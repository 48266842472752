import { types, flow, getParent } from "mobx-state-tree";
import { dataURLtoFile } from "../../utils/helper";
import authAPI from "../../api/AuthDev";
import { EP_ROOT as __ } from "../../utils/endpoints";
import ContentAPI from "../../api/ContentServiceAPI";
import { LS_KEY_FOLKS_USER_TOKEN, LS_KEY_FOLKS_USER_ID } from "../../utils/constants";
import { addressObjectModel } from "./models/profileModel";

const profileStoreModel = {
  userName: "",
  avatarImageUrl: "",
  dob: "",
  gender: "",
  email: "",
  bio: "",
  addressList: types.optional(types.array(addressObjectModel), []),
};

const profileStoreActions = (self) => ({
  getProfileInfo: flow(function* getProfileInfo() {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
    const config = { headers: { token: folksUserToken } };

    try {
      const res = yield authAPI.get(__.AUTH_SERVICE.GET_USER_PROFILE(folksUserId), config);
      if (res?.status === 200) {
        self.userName = res?.data?.username;
        self.avatarImageUrl = res?.data?.image_url;
        self.dob = res?.data?.date_of_birth;
        self.gender = res?.data?.gender;
        self.email = res?.data?.email;
        self.bio = res?.data?.bio;
        self.addressList = res?.data?.addresses || [];
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while loading profile info!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while loading profile info!");
      return Promise.reject(error);
    }
  }),

  updateUserName: flow(function* updateUserName(userName) {
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID);
    const payload = { username: userName, user_id: folksUserId };

    try {
      const res = yield authAPI.put(__.AUTH_SERVICE.UPDATE_USER_NAME, payload);
      if (res?.status === 200) {
        self.userName = userName;
        getParent(self).notificationStore.triggerSuccess("Updated user name!");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while updating user name!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating user name!");
      return Promise.reject(error);
    }
  }),

  updateBio: flow(function* updateBio(bio) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID);
    const payload = { bio: bio, user_id: folksUserId };
    const config = {
      headers: {
        "Content-Type": "application/json",
        token: folksUserToken,
      },
    };

    try {
      const res = yield authAPI.patch(__.AUTH_SERVICE.UPDATE_USER_PROFILE, payload, config);
      if (res?.status === 200) {
        self.bio = bio;
        getParent(self).notificationStore.triggerSuccess("Updated user bio");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while updating user bio!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating user bio !");
      return Promise.reject(error);
    }
  }),

  updateBirthDate: flow(function* updateBirthDate(dateStr) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID);
    const payload = { date_of_birth: dateStr, user_id: folksUserId };
    const config = { headers: { token: folksUserToken } };

    try {
      const res = yield authAPI.put(__.AUTH_SERVICE.UPDATE_DOB, payload, config);
      if (res?.status === 200) {
        self.dob = dateStr;
        getParent(self).notificationStore.triggerSuccess("Date of birth updated successfully!");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while updating DOB!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating DOB!");
      return Promise.reject(error);
    }
  }),

  updateGender: flow(function* updateGender(genderStr) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID);
    const payload = { gender: genderStr, user_id: folksUserId };
    const config = { headers: { token: folksUserToken } };

    try {
      const res = yield authAPI.put(__.AUTH_SERVICE.UPDATE_GENDER, payload, config);
      if (res?.status === 200) {
        self.gender = genderStr;
        getParent(self).notificationStore.triggerSuccess("Gender updated successfully!");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while updating gender!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating gender!");
      return Promise.reject(error);
    }
  }),

  updateProfilePhoto: flow(function* updateProfilePhoto(base64URL) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const imageFile = dataURLtoFile(base64URL);
    const payload = new FormData();
    payload.append("file", imageFile, `${self.userName}-avatar.png`);
    const config = { headers: { token: folksUserToken } };

    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.UPDATE_PROFILE_PIC, payload, config);
      if (res?.status === 200) {
        self.avatarImageUrl = base64URL;
        getParent(self).notificationStore.triggerSuccess("Profile image updated successfully!");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while updating profile image!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating profile image!");
      return Promise.reject(error);
    }
  }),

  // Manage Address APIs
  // Add new address (Create)
  addNewAddress: flow(function* addNewAddress(values) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
    const payload = { ...values, user_id: folksUserId };
    const config = { headers: { token: folksUserToken } };

    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.ADD_ADDRESS, payload, config);
      if (res?.status === 200) {
        yield self.getAddressList();
        getParent(self).notificationStore.triggerSuccess("Address added!");
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while adding new address!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while adding new address!");
      return Promise.reject(error);
    }
  }),

  // Get address list (Read) ***(Requires adcuratio user ID)***
  getAddressList: flow(function* getAddressList(emitToast = false) {
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
    try {
      const res = yield authAPI.get(__.AUTH_SERVICE.GET_ALL_ADDRESSES(folksUserId));
      if (res?.status === 200) {
        self.addressList = res?.data?.addresses || [];
        if (emitToast) {
          getParent(self).notificationStore.triggerSuccess("Address list fetch successful!");
        }
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while fetching address list!");
      }
    } catch (error) {
      if (emitToast) {
        getParent(self).notificationStore.triggerError("Something went wrong while fetching address list!");
      }
      return Promise.reject(error);
    }
  }),

  // Update existing address (Update) ***(Requires adcuratio user ID)***
  updateAddress: flow(function* updateAddress(address_id, values) {
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
    const config = { headers: { userID: folksUserId } };

    try {
      const res = yield authAPI.put(__.AUTH_SERVICE.UPDATE_ADDRESS(address_id), values, config);
      if (res?.status === 200) {
        yield self.getAddressList();
        getParent(self).notificationStore.triggerSuccess("Address updated!");
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while updating address!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while updating address!");
      return Promise.reject(error);
    }
  }),

  // Delete an address (Delete) ***(Requires adcuratio user ID)***
  deleteAddress: flow(function* deleteAddress(address_id) {
    const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
    const config = { headers: { userID: folksUserId } };
    try {
      const res = yield authAPI.delete(__.AUTH_SERVICE.DELETE_ADDRESS(address_id), config);
      if (res?.status === 200) {
        yield self.getAddressList();
        getParent(self).notificationStore.triggerSuccess("Address deleted!");
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong with deleting address!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Something went wrong while deleting an address!");
      return Promise.reject(error);
    }
  }),

  // --------------- user all reviews -----------------
  getUserAllReviews: flow(function* getUserAllReviews(limit = 3, skip = 0) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_PROFILE_DATA({ service: "all", limit, skip }));
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data });
      } else {
        throw new Error("Something went wrong while fetching user profile data!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching user profile data!",
      });
    }
  }),

  // --------------- user playlists -----------------
  getUserPlayListData: flow(function* getUserPlayListData(limit = 3, skip = 0) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_PROFILE_DATA({ service: "playlist", limit, skip }));
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data?.playlist_data });
      } else {
        throw new Error("Something went wrong while fetching user playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching playlist!",
      });
    }
  }),

  // --------------- user content reviews -----------------
  getUserContentReviews: flow(function* getUserContentReviews(limit = 1, skip = 0) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_PROFILE_DATA({ service: "content-review", limit, skip }));
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res.data.content_review });
      } else {
        throw new Error("Something went wrong while fetching user content reviews!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching user content reviews!",
      });
    }
  }),

  loadProfileData: flow(function* loadProfileData() {
    try {
      yield self.getProfileInfo();
    } catch (e) {}

    return Promise.resolve({ success: true });
  }),
});

const profileStoreViews = (self) => ({
  get getUserName() {
    return self.userName;
  },
  get getAvatarImageUrl() {
    return self.avatarImageUrl;
  },
  get getDob() {
    return self.dob;
  },
  get getGender() {
    return self.gender;
  },
  get getEmail() {
    return self.email;
  },
  get getBio() {
    return self.bio;
  },
});

export const profileStore = types
  .model("Profile Store", profileStoreModel)
  .actions(profileStoreActions)
  .views(profileStoreViews);
