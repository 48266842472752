import { sortByRowPosition } from "../sorts/contentSorts";
import { store as RootStore } from "../..";
import { CONTENT_SETTINGS } from "../../../utils/contentSettings";

const ottShowContentTypeVals = [23];
const peopleContentTypeVals = [22, 27, 28, 29];
const genreContentTypeVals = [32, 33];
const intensions_for_web_banners = ["all", "web"];

// API data filters.
// mediaRows(arr) -> Row -> mediaItems(arr) -> Item -> actions(arr) -> Action
export function isValidAction(obj) {
  return (
    obj?.title &&
    obj?.icon &&
    obj?.chat_action?.action_type === "VIEW_DEEPLINK" &&
    (obj?.chat_action?.action_id || obj?.chat_action?.action_meta?.deeplink_web)
  );
}

function isValidOttShowMediaItem(obj) {
  return (
    obj?.item_type &&
    obj?.item_id &&
    obj?.image &&
    obj?.image_hd &&
    obj?.external_ids?.imdb_id &&
    obj?.title &&
    obj?.subtitle &&
    obj?.description &&
    (typeof obj?.video === "string" || obj?.video === null) // Video links are sometimes not present or values are not as expected.
  );
}

function isValidGenreMediaItem(obj) {
  return obj?.item_type && obj?.item_id && obj?.image && obj?.image_hd && obj?.title;
}

function isValidPeopleMediaItem(obj) {
  return obj?.item_type && obj?.item_id && obj?.image && obj?.title;
}

function isValidMediaItem(obj) {
  return obj?.item_type && obj?.item_id && obj?.image && obj?.image_hd && obj?.title;
}

function isValidMediaRow(obj) {
  return (
    obj?.title &&
    // && obj?.type
    obj?.display_config?.width &&
    obj?.display_config?.height &&
    obj?.media_items?.length
  );
}

function sanitizeDisplayConfig(size_obj) {
  if (size_obj?.height && size_obj?.width && typeof size_obj.height === "number" && typeof size_obj.width === "number")
    return size_obj;
  return CONTENT_SETTINGS.MEDIA_CARD_SIZE;
}

function extractOttPlatformNameFromTitle(title) {
  if (title?.includes("Watch on ")) {
    let sanitizedTitle = title?.replace("Watch on ", "");
    return sanitizedTitle;
  } else {
    return title;
  }
}

function isAllowedOttAction(action) {
  return RootStore.contentSettingsStore.getSelectedOttAppTitle.includes(extractOttPlatformNameFromTitle(action?.title));
}

function isValidMetaTitle(obj) {
  return obj?.type && obj?.title && typeof obj?.position === "number";
}

function isValidBanner(obj) {
  return (
    obj.image &&
    obj?.action?.action_id &&
    obj?.action?.action_type &&
    obj?.image_l &&
    obj?.intended_application &&
    intensions_for_web_banners.includes(obj.intended_application)
  );
}

export const sanitizeHomeBanners = (bannerDataArr) => {
  const sanitizedBanners = bannerDataArr.filter(isValidBanner);
  return sanitizedBanners?.length ? sanitizedBanners : [];
};

export const sanitizeMetaTitles = (metaTitleArr) => {
  const sanitizedMetaTitles = metaTitleArr?.filter(isValidMetaTitle)?.sort((a, b) => a.position - b.position); // Meta titles should be sorted.

  return sanitizedMetaTitles?.length ? sanitizedMetaTitles : [];
};

export const sanitizeMediaRows = (mediaRowsArr) => {
  const sanitizedRows = mediaRowsArr
    ?.map((row) => {
      // ott or shows row.
      if (ottShowContentTypeVals.includes(row?.content_type)) {
        return {
          ...row,
          display_config: sanitizeDisplayConfig(row?.display_config),
          media_items: row?.media_items
            ?.map((mediaItem) => {
              return {
                ...mediaItem,
                actions: {
                  action_list: mediaItem?.actions?.action_list || [],
                  content_ids: JSON.stringify(mediaItem?.actions?.content_ids),
                },
              };
            })
            ?.filter(isValidOttShowMediaItem),
        };
      }
      // Genre row.
      else if (genreContentTypeVals.includes(row?.content_type)) {
        return {
          ...row,
          display_config: sanitizeDisplayConfig(row?.display_config),
          media_items: row?.media_items
            ?.map((mediaItem) => ({
              ...mediaItem,
              actions: {
                action_list: mediaItem?.actions?.action_list || [],
                content_ids: JSON.stringify(mediaItem?.actions?.content_ids),
              },
              item_id: `${mediaItem?.item_id}`,
            }))
            ?.filter(isValidGenreMediaItem),
        };
      }
      // Peoples row.
      else if (peopleContentTypeVals.includes(row?.content_type)) {
        return {
          ...row,
          display_config: sanitizeDisplayConfig(row?.display_config),
          media_items: row?.media_items?.filter(isValidPeopleMediaItem),
        };
      }
      // Default case (Episodes etc.)
      else {
        return {
          ...row,
          display_config: sanitizeDisplayConfig(row?.display_config),
          media_items: row?.media_items
            ?.map((mediaItem) => {
              return {
                ...mediaItem,
                actions: {
                  action_list: mediaItem?.actions?.action_list || [],
                  content_ids: JSON.stringify(mediaItem?.actions?.content_ids),
                },
              };
            })
            ?.filter(isValidMediaItem),
        };
      }
    })
    ?.filter(isValidMediaRow)
    ?.sort(sortByRowPosition);

  return sanitizedRows?.length ? sanitizedRows : [];
};
