import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { store as RootStore } from "../store";

export const flattenObject = (obj) => {
  const retunedObj = {};

  for (const i in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, i)) {
      continue;
    }
    if (typeof obj[i] === "object") {
      const flatObject = flattenObject(obj[i]);
      for (const x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
          continue;
        }
        retunedObj[`${i}.${x}`] = flatObject[x];
      }
    } else {
      retunedObj[i] = obj[i];
    }
  }
  return retunedObj;
};

const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,25}$/;
export const validatePassword = (password) => {
  if (!password) return false;
  if (!passwordRegex.test(password)) return false;
  return true;
};

const emailRegex =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

export const validateEmail = (email) => {
  if (!emailRegex.test(email)) return false;
  return true;
};

export const getPasswordMessage = (fieldName) => {
  return `${fieldName} length should be more than 8 characters and it should atleast include one lowercase letter and one digit!`;
};

export const jwtDecode = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    return {
      success: true,
      data: decodedToken,
    };
  } catch (error) {
    return {
      success: false,
      message: "Decoding failed, Bad token!",
    };
  }
};

export const isJWTexpired = (token) => {
  const decodedToken = jwt_decode(token);

  const dateNow = new Date();
  if (decodedToken?.exp < dateNow.getTime()) {
    return true;
  } else {
    return false;
  }
};

// helper function: generate a new file from base64 String
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export const processUTCtoEST = (s, defaultFormat = "DD MMM YYYY") => {
  if (s) {
    if (typeof s !== "string") {
      return moment().tz("America/New_York").format(defaultFormat);
    }
    return moment(s).tz("America/New_York").format(defaultFormat);
  }
  return moment().tz("America/New_York").format(defaultFormat);
};

export const validatePasswordText = (password) => {
  if (!passwordRegex.test(password)) {
    return {
      status: false,
      message:
        "Password length should be more than 8 characters and it should atleast include one lowercase letter and one digit!",
    };
  } else {
    return { status: true };
  }
};

export const isValidRoute = (routeList, pathName) => {
  let isValid = false;
  if (routeList.some((route) => route.path === pathName)) {
    isValid = true;
  }
  return isValid;
};

export const isImdbId = (idStr) => {
  const regx_imdb_id = /ev\d{7}\/\d{4}(-\d)?|(ch|co|ev|nm|tt)\d{7}/;

  if (!idStr || typeof idStr !== "string") {
    return false;
  } else if (regx_imdb_id.test(idStr)) {
    return true;
  } else {
    return false;
  }
};

export const playlistShareUrl = (playlistId) => {
  return `${window.location.protocol + "//" + window.location.host}/content/playlist/${playlistId}`;
};

export const dateXyearsAgo = (numOfYears, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears);
  return date;
};

export const analyticsUrl = "/events/web/analytics";

export const deProxyJSONData = (data) => JSON.parse(JSON.stringify(data));

export const getLocalDateTimeStr = () => {
  // Returns local date-time string in ISO8601 format.
  // Ex: "2014-01-01T23:28:56.782"
  const date = new Date();

  function pad(number) {
    var r = String(number);
    if (r.length === 1) r = "0" + r;
    return r;
  }

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." +
    String((date.getMilliseconds() / 1000).toFixed(3)).slice(2, 5)
  );
};

export const checkAgent = () => {
  const agent_str = window.navigator.userAgent.toLowerCase();
  if (agent_str?.indexOf("iphone") !== -1) return "iphone";
  if (agent_str?.indexOf("android") !== -1) return "android";
  if (agent_str?.indexOf("ipad") !== -1) return "ipad";
  else return "default"; // Web.
};

export const getActionsData = (actions, deviceType = "E") => {
  const actionList = actions?.action_list?.length ? actions?.action_list : [];
  const contentIdLinkObj = actions?.content_ids
    ? JSON.parse(
        typeof actions?.content_ids === "string" ? actions?.content_ids : JSON.stringify(actions?.content_ids || {})
      )
    : {}; // -> actions.content_ids are stringified.
  if (!(actionList?.length && Object.keys(contentIdLinkObj)?.length)) return [];

  const availableDeviceActions = actionList?.filter((actionStr) => actionStr?.split(".")?.[1] === deviceType);
  if (!availableDeviceActions?.length) return [];

  // Deeplink generation
  const actionsDataArr = [];
  const ottAppsData = RootStore.contentSettingsStore.allOttApps;
  const deeplinkMetaData = JSON.parse(RootStore.feedsStore.deeplinkMeta);

  availableDeviceActions?.forEach((actionStr) => {
    const ottId = actionStr?.split(".")?.[0];
    const contentId = actionStr?.split(".")?.[2];
    const connnectionId = `${ottId}.${contentId}`;

    // is action data generation is possible:
    const deeplinkMetaId = `${ottId}.${deviceType}`;
    const deeplinkStr = deeplinkMetaData[deeplinkMetaId];
    const replacementStr = contentIdLinkObj[connnectionId];
    const ottAppDataObj = ottAppsData?.find((ottObj) => ottObj?.id?.toString() === ottId);

    if (deeplinkStr && replacementStr && ottAppDataObj?.id) {
      let functionalDeeplink = deeplinkStr?.replace("**", replacementStr);
      actionsDataArr.push({
        id: ottAppDataObj?.id,
        icon: ottAppDataObj?.image,
        title: ottAppDataObj?.title,
        redirect_url: functionalDeeplink,
      });
    }
  });

  return actionsDataArr;
};

export const detailedMediaType = (mediaType, mediaId) => {
  if (mediaType === "deeplink") {
    return "deeplink";
  } else if (mediaType === "app_screenshot") {
    return "app_screenshot";
  } else if (mediaType === "episode") {
    return "episode";
  } else if (mediaType === "ott" || mediaType === "show" || mediaType === "facet") {
    if (isImdbId(mediaId)) {
      if (mediaType === "show") {
        return "show";
      } else if (mediaType === "facet") {
        return "facet";
      } else if (mediaType === "ott") {
        return "ott";
      } else {
        return "cross";
      }
    } else {
      if (mediaType === "facet") {
        return "genre";
      } else {
        return "cross";
      }
    }
  } else {
    return "cross";
  }
};
