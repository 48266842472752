import { flow, types, getParent } from "mobx-state-tree";
import { toJS } from "mobx";

import ContentAPI from "../../api/ContentServiceAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";

import { playlistModel } from "./models/playlistModel";

import { sanitizePlaylistData } from "./filters/playlistFilter";

const PlaylistStoreModel = {
  userPlaylistIds: types.optional(types.array(types.number), []),
  listOfPlaylists: types.optional(types.array(playlistModel), []),
  listOfSubscribedPlaylist: types.optional(types.array(playlistModel), []),
};

const PlaylistStoreActions = (self) => ({
  setViewAnalyticsDataForPlaylist(watchlist_id, count) {
    const foundIndex = self.listOfPlaylists.findIndex((x) => x.watchlist_id === watchlist_id);
    self.listOfPlaylists[foundIndex].analytics_data.watch_count = count;
  },

  setViewAnalyticsDataForSubscribedPlaylist(watchlist_id) {
    const foundIndex = self.listOfSubscribedPlaylist.findIndex((x) => x.watchlist_id === watchlist_id);
    const count = self.listOfSubscribedPlaylist[foundIndex]?.analytics_data?.watch_count + 1;
    self.listOfSubscribedPlaylist[foundIndex].analytics_data.watch_count = count;
  },

  setUnSubscribeAnalytics(watchlist_id) {
    self.listOfSubscribedPlaylist = self.listOfSubscribedPlaylist.filter((item) => item.watchlist_id !== watchlist_id);
    getParent(self).searchStore.updateSearchData(watchlist_id);
  },

  setListOfPlaylists(data) {
    try {
      self.listOfPlaylists = sanitizePlaylistData(data);
    } catch (e) {
      throw new Error(`MST Error: While storing playlist data! \n\n ${e}`);
    }
  },

  setListOfSubscribedPlaylist(data) {
    try {
      self.listOfSubscribedPlaylist = sanitizePlaylistData(data);
    } catch (e) {
      throw new Error(`MST Error: While storing subscribed data! \n\n ${e}`);
    }
  },

  addToSubscribePlaylist(data) {
    self.listOfSubscribedPlaylist = [...self.listOfSubscribedPlaylist, JSON.parse(JSON.stringify(data))];
  },

  // -------------- Create Playlist -----------------
  createNewPlaylist: flow(function* createNewPlaylist(playlist_name, playlist_privacy) {
    const payload = { watchlist_name: playlist_name, privacy: playlist_privacy };
    try {
      const res = yield ContentAPI.post(__.CONTENT_SERVICE.CREATE_PLAYLIST, payload);
      if (res?.status === 201 && res?.data?.data?.watchlist_id) {
        const newPlaylistObj = {
          analytics_data: {
            browse_count: 0,
            score: 0,
            subscribed_count: 0,
            watch_count: 0,
          },
          author_name: toJS(getParent(self)?.profileStore?.userName),
          image_hd_url: null,
          image_url: null,
          is_subscribe: false,
          is_owner: true,
          privacy: res?.data?.data?.privacy || null,
          timestamp: res?.data?.data?.timestamp || null,
          user_id: toJS(getParent(self)?.auth?.folksUserId) || null,
          video_count: 0,
          watchlist_id: res?.data?.data?.watchlist_id || null,
          watchlist_name: res?.data?.data?.watchlist_name || null,
        };
        self.listOfPlaylists = [newPlaylistObj, ...self.listOfPlaylists];
        self.getAllPlaylists(); // Background call.
        return Promise.resolve({ success: true, data: res?.data?.data?.watchlist_id });
      } else {
        throw new Error(res.data || "Something went wrong");
      }
    } catch (error) {
      return Promise.reject({ success: false, msg: error?.response?.data?.message || "Something went wrong" });
    }
  }),

  // -------------- Read playlists ----------------
  getAllPlaylists: flow(function* getAllPlaylists() {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.READ_PLAYLISTS());
      self.setListOfPlaylists(res?.data?.data?.my_data);
      self.setListOfSubscribedPlaylist(res?.data?.data?.subscribed_data);
      return res;
    } catch (error) {
      return error;
    }
  }),

  // -------------- Update playlist ----------------
  updatePlaylist: flow(function* updatePlaylist(playlist_id, playlist_name, playlist_privacy) {
    const payload = { watchlist_name: playlist_name, privacy: playlist_privacy };
    try {
      const res = yield ContentAPI.put(__.CONTENT_SERVICE.UPDATE_PLAYLIST(playlist_id), payload);
      if (res?.status === 201) {
        self.listOfPlaylists
          .find((playlist) => playlist.watchlist_id === playlist_id)
          .updatePlaylist(playlist_name, playlist_privacy);
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to update playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Unable to update playlist!");
      return Promise.reject({ success: false, message: error.message || "Unable to update playlist!" });
    }
  }),

  // -------------- Delete playlist ----------------
  deletePlaylist: flow(function* deletePlaylist(playlist_id) {
    try {
      const res = yield ContentAPI.delete(__.CONTENT_SERVICE.DELETE_PLAYLIST(playlist_id));
      yield self.getAllPlaylists();
      if (res?.status === 200) {
        self.listOfPlaylists = self.listOfPlaylists.filter((item) => item?.watchlist_id !== playlist_id);
        getParent(self).notificationStore.triggerSuccess("Playlist deleted successfully!");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to delete Playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Unable to delete playlist");
      return Promise.reject({ success: false, message: error.message || "Unable to delete Playlist!" });
    }
  }),

  // --------------- Subscribe to a playlist -----------------
  subscribeToPlaylist: flow(function* subscribeToPlaylist(playlist_id) {
    try {
      const res = yield ContentAPI.put(__.CONTENT_SERVICE.SUBSCRIBE_PLAYLIST(playlist_id));
      if (res?.status === 200 && res?.data) {
        // yield self.getAllPlaylists();
        return Promise.resolve({ success: true, data: res?.data });
      } else {
        throw new Error("Something went wrong subscribing playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong subscribing playlist!",
      });
    }
  }),

  // --------------- Read playlist content -----------------
  getPlaylistContent: flow(function* getPlaylistContent(playlist_id) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.READ_PLAYLIST_CONTENT(playlist_id));
      if (res?.status === 200) {
        return Promise.resolve({ success: true, data: res?.data?.data });
      } else {
        throw new Error("Unable to fetch playlist content!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Unable to fetch playlist content!");
      return Promise.reject({ success: false, message: error.message || "Unable to fetch playlist content!" });
    }
  }),

  // --------------- Update/Add one content to multiple playlist -----------------
  addTitleToPlaylists: flow(function* addTitleToPlaylists(playlist_id_arr, imdbId) {
    const payload = { imdb_id: imdbId, watchlist_ids: playlist_id_arr };
    try {
      const res = yield ContentAPI.post(__.CONTENT_SERVICE.UPDATE_PLAYLIST_CONTENT, payload);
      yield self.getAllPlaylists();
      if (res?.status === 201) {
        yield self.getPlaylistsForTitle(imdbId);
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to add title to playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Unable to add title to playlist!");
      return Promise.reject({ success: false, message: error.message || "Unable to add title to playlist!" });
    }
  }),

  // --------------- Delete multiple content from one playlist -----------------
  deleteTitlesFromPlaylist: flow(function* deleteTitlesFromPlaylist(playlist_id, imdb_id_arr) {
    const payload = { imdb_ids: imdb_id_arr };
    try {
      const res = yield ContentAPI.post(__.CONTENT_SERVICE.DELETE_PLAYLIST_CONTENT(playlist_id), payload);
      if (res?.status === 200) {
        self.getAllPlaylists();
        getParent(self).notificationStore.triggerSuccess("Movie Deletion Successfull");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to delete Movie!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Unable to delete Movie!");
      return Promise.reject({ success: false, message: error.message || "Unable to delete Movie!" });
    }
  }),

  // --------------- Get playlist associated to a particular content -----------------
  getPlaylistsForTitle: flow(function* getPlaylistsForTitle(imdb_id) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.READ_PLAYLIST_IDS_FOR_CONTENT(imdb_id));
      if (res?.status === 201) {
        self.userPlaylistIds = res?.data?.data?.watchlist_ids;
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to Fetch selected playlsit!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError(error.message || "Unable to Fetch selected playlsit!");
      return Promise.reject({ success: false, message: error.message || "Unable to Fetch selected playlsit!" });
    }
  }),

  loadUserPlaylistData: flow(function* loadUserPlaylistData() {
    try {
      yield self.getAllPlaylists();
    } catch (e) {
      // Do something.
    }
    return Promise.resolve({ success: true });
  }),
});

const PlaylistStoreViews = (self) => ({});

export const playlistStore = types
  .model("playlistStore", PlaylistStoreModel)
  .actions(PlaylistStoreActions)
  .views(PlaylistStoreViews);
