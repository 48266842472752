export const ModalHeadingText = (props) => (
  <div style={{ fontSize: "1.1vw", fontWeight: "bold", color: "#A6A6A6", textAlign: "center" }}>{props.children}</div>
);

export const ModalContentText = (props) => (
  <div style={{ fontSize: "1.05vw", textAlign: "center" }}>{props.children}</div>
);

export const ModalButtonText = (props) => (
  <span style={{ fontSize: "1vw", textAlign: "center" }}>{props.children}</span>
);
