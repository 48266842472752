import React, { useState, useEffect, useLayoutEffect } from "react";
import { Redirect, Route, Switch, useLocation, useHistory } from "react-router-dom";
import { accountRoutes, allRouteList, authRoutes } from "../routes/routes";
import { useMst } from "../store";
import { guestHeader, familyHeader } from "./headerData";
import { USER_TYPE } from "../store/auth_store/models/authModel";
import { isValidRoute } from "../utils/helper";
import { URLS } from "../routes/routeURL";
import { Layout } from "antd";

import AppHeader from "./header/Header";
import AccountsSidebar from "./accounts-sidebar/AccountsSidebar";
import FrontLoader from "../components/front-loader/FrontLoader";
import PageNotAuthorized from "../components/page-not-authorized/PageNotAuthorized";
import PageNotFound from "../components/page-not-found/PageNotFound";
import styles from "./layout.module.css";
import GuestUserModal from "../components/guest-user-modal/GuestUserModal";
const { Content } = Layout;

const AppLayout = () => {
  const rootStore = useMst();
  const history = useHistory();
  const location = useLocation();

  const { auth, browserStore } = rootStore;
  const [loading, setLoading] = useState(true);
  const onAccountsRoute = isValidRoute(Object.values(accountRoutes), location.pathname);
  const onAuthRoute = isValidRoute(Object.values(authRoutes), location.pathname);

  const preLoad = async () => {
    try {
      if (auth.folksUserType === USER_TYPE.NORMAL) {
        await rootStore.normalDataLoad(); // This will always resolve.
      } else if (auth.folksUserType === USER_TYPE.GUEST) {
        await rootStore.guestDataLoad(); // This will always resolve.
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (browserStore.isMobileUser) {
      history.push(URLS.LANDING);
    }
  }, []);

  useEffect(() => {
    // Why?: Data load not required for the Unauthenticated User.
    if ([USER_TYPE.NORMAL, USER_TYPE.GUEST].includes(auth.folksUserType)) {
      preLoad();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <FrontLoader />
      ) : (
        <Layout className={styles.topLayoutContainer}>
          {/* App Header */}
          {onAuthRoute ? null : browserStore.isMobileUser ? (
            <AppHeader mobileHeader={true} />
          ) : (
            <AppHeader headerData={auth.isNormalUser ? familyHeader : guestHeader} />
          )}
          {/* App Content */}
          <Layout className={styles.contentLayoutContainer}>
            {onAccountsRoute && <AccountsSidebar />} {/* // Ant Sider for accounts module */}
            <Content>
              <Switch>
                {allRouteList.map(({ component: Component, ...route }) => (
                  <Route
                    key={route.id}
                    exact={true}
                    path={route.path}
                    render={(routeProps) => {
                      if (route.access.includes(auth.folksUserType)) {
                        return <Component {...routeProps} />;
                      } else {
                        return <Redirect to={{ pathname: URLS.UNAUTHORIZED_BLOCK }} />;
                      }
                    }}
                  />
                ))}
                <Route exact path={URLS.UNAUTHORIZED_BLOCK} component={PageNotAuthorized} />
                <Route path="*" component={PageNotFound} />
              </Switch>
            </Content>
          </Layout>
          <GuestUserModal />
        </Layout>
      )}
    </>
  );
};

export default AppLayout;
