import React, { useState, useEffect } from "react";
import { useMst } from "../../store";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { REDIRECTION_ROUTES } from "../../utils/redirectionSettings";
import { URLS } from "../../routes/routeURL";

// Components
import FolksAvatar from "../../components/folks-avatar/FolksAvatar";
import FolksModal from "../../components/folks-modal/FolksModal";
import { ModalHeadingText } from "../../components/typography/ModalText";
import { ModalFooterButton } from "../../components/folks-modal/ModalFooterButton";
import { Drawer, Space } from "antd";
import { FolksMobileHeader } from "./styledComponent";

// Icons
import { CloseOutlined } from "@ant-design/icons";
import { MdOutlineSearch, MdMenu, MdClose } from "react-icons/md";
import hamburger from "../../assets/images/hamburger.svg";

// CSS Module [Scope => Header Component]
import styles from "./header.module.css";

// Images
import logo from "../../assets/images/fm_logo.svg";

const AppHeader = (props) => {
  const { headerData, mobileHeader } = props;
  const { auth, profileStore } = useMst();
  const history = useHistory();
  const location = useLocation();

  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [headerDark, setHeaderDark] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      setHeaderDark(true);
    } else {
      setHeaderDark(false);
    }
  }

  const handleOk = async () => {
    setIsLogoutModalVisible(false);
    try {
      await auth.logout();
      window.location.href = REDIRECTION_ROUTES.HEADER_LOGOUT;
    } catch (e) {
      // do something
    }
  };

  const handleLogoutModalCancel = () => {
    setIsLogoutModalVisible(false);
  };

  const handleLogout = () => {
    setIsLogoutModalVisible(true);
  };

  const handleMenuItemClick = (tab) => {
    if (tab.item_id === "log-out") {
      handleLogout();
    } else if (tab.path) {
      history.push(tab.path);
    }
  };

  const handleHomeButton = () => {
    if (mobileHeader) {
      // Redirect to app.
      window.location = `https://go.folksmedia.com?apn=com.folksmediaapp&ibi=com.adcuratio.folksmedia&isi=1579558800&link=${encodeURIComponent(
        `https://app.folksmedia.com/`
      )}`;
    } else {
      history.push(URLS.CONTENT_HOME);
    }

    // history.push(URLS.CONTENT_HOME);
  };

  useEffect(() => {
    headerData?.left?.forEach((obj) => {
      if (obj.path === location.pathname) {
        // setCurrentLeftMenuKey(obj.item_id);
      }
    });
  });

  const headerItemsLeft = headerData?.left?.map((item) => {
    return {
      key: `${item?.item_id}`,
      label: (
        <span key={`${item?.item_id}`} className={styles.headerMenuItem} onClick={() => handleMenuItemClick(item)}>
          {item.title}
        </span>
      ),
    };
  });

  const headerItemsRight = headerData?.right?.map((item) => {
    return {
      key: `${item?.item_id}`,
      label: (
        <div
          key={`${item?.item_id}`}
          style={{ position: "relative", marginRight: `${item.item_id === "my-account" ? "50px" : ""}` }}
          className={
            item.item_id === "sign-up"
              ? styles.signUpBtn
              : item.item_id === "log-in"
              ? styles.signUpBtn
              : styles.headerMenuItem
          }
          onClick={() => handleMenuItemClick(item)}
        >
          {item.item_id === "search" ? (
            <div style={{ position: "absolute", top: "-6px", right: "45px", fontSize: "22px" }}>
              <MdOutlineSearch />
            </div>
          ) : null}
          {item.title}

          {item.item_id === "my-account" ? (
            <div style={{ position: "absolute", top: "0px", left: "85px" }}>
              <FolksAvatar
                name={profileStore?.getUserName || ""}
                round={true}
                size="24px"
                src={profileStore?.getAvatarImageUrl || ""}
              />
            </div>
          ) : null}
        </div>
      ),
    };
  });

  const handleRedirection = (name, message = "") => {
    onClose();
    const route_name = name;
    history.push({ pathname: URLS.COMMON_MESSAGE({ route_name }), state: { message } });
  };

  const desktopView = () => {
    return (
      <>
        {/* Desktop Header */}
        <div className={styles.header}>
          <div className={`${styles.headerContainer} ${headerDark ? styles.headerDark : ""}`}>
            {/* Logo */}
            <div className={styles.logoContainer} onClick={handleHomeButton}>
              <img src={logo} alt="Folkslogo" className={styles.headerLogo} />
            </div>
            {/* Left Menu */}
            <div className={styles.headerContainerLeft}>{headerItemsLeft.map((mObj) => mObj.label)}</div>
            {/* Right Menu */}
            <div className={styles.headerContainerRight}>{headerItemsRight.map((mObj) => mObj.label)}</div>
          </div>
        </div>

        {/* Logout Confirmation Modal */}
        <FolksModal visible={isLogoutModalVisible} onCancel={handleLogoutModalCancel}>
          <div className={styles.modalContainer}>
            <div>
              <ModalHeadingText>Are you sure you want to logout ?</ModalHeadingText>
              <div className={styles.modalFooterContainer}>
                <div>
                  <ModalFooterButton
                    className={styles.modalFooterBtn}
                    color="#A6A6A6"
                    onClick={handleLogoutModalCancel}
                  >
                    No
                  </ModalFooterButton>
                  <ModalFooterButton className={styles.modalFooterBtn} color="#289E5E" onClick={handleOk}>
                    Yes
                  </ModalFooterButton>
                </div>
              </div>
            </div>
          </div>
        </FolksModal>
      </>
    );
  };

  const mobileView = () => {
    return (
      <>
        <FolksMobileHeader>
          <div className={styles.mobileHeaderContainer}>
            <div className={styles.mobileLogoConatiner}>
              <img src={hamburger} alt="hamburger" className={styles.mobileHamburger} onClick={() => showDrawer()} />
              <img
                src={logo}
                alt="Folkslogo"
                className={styles.mobileHeaderLogo}
                onClick={() => history.push(URLS.LANDING)}
              />
            </div>
          </div>
        </FolksMobileHeader>

        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"key"}
          width={"75%"}
          extra={
            <Space>
              <p>Some contents...</p>
            </Space>
          }
        >
          <div className={styles.headerDrawerContainer}>
            <CloseOutlined className={styles.closeIcon} onClick={() => onClose()} />
            <img src={logo} alt="Folkslogo" className={styles.headerDrawerLogo} />
          </div>
          <br /> <br />
          <p
            onClick={() =>
              handleRedirection("Streaming Guide", "To find and watch Movies & Shows\nDownload the FolksMedia App")
            }
          >
            Streaming Guide
          </p>
          <p onClick={() => handleRedirection("Movies")}>Search Movies</p>
          <p onClick={() => handleRedirection("Rewards")}>Rewards</p>
        </Drawer>
      </>
    );
  };

  if (mobileHeader || isMobile) {
    return mobileView();
  } else {
    return desktopView();
  }

  // return desktopView();
};

AppHeader.propTypes = {
  headerData: PropTypes.object,
  mobileHeader: PropTypes.bool,
};

AppHeader.defaultProps = {
  headerData: {},
  mobileHeader: false,
};

export default AppHeader;
