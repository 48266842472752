export const URLS = {
  // Landing URLs (Top level)
  LANDING: "/about-us",
  PRIVACY_POLICY: "/privacy-policy",
  ABOUT_COMPANY: "/about-company",
  TERMS_OF_USE: "/terms-of-use",
  UNAUTHORIZED_BLOCK: "/unauthorized-access",

  // Auth URLs (Top level)
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASS: "/forgot-password",
  LINK_DEVICE: "/linkdevice",

  // Public User Page URLs (Top level)
  PUBLIC_USER_PROFILE: ({ user_id = ":user_id" } = {}) => `/user-profile/${user_id}`,
  PUBLIC_USER_PLAYLIST: ({ playlist_id = ":playlist_id" } = {}) => `/playlist/${playlist_id}`,

  // Content URLs
  CONTENT_HOME: "/",
  CONTENT_MOVIES: "/content/movies",
  CONTENT_SHOWS: "/content/tv-shows",
  CONTENT_SEARCH: "/content/search",
  CONTENT_DETAILS: ({ media_type = ":media_type", media_id = ":media_id" } = {}) =>
    `/content/details/${media_type}/${media_id}`,
  CONTENT_META_EXPAND: ({ row_type = ":row_type", row_position = ":row_position?" } = {}) =>
    `/content/meta/${row_type}/${row_position}`,
  COMMON_MESSAGE: ({ route_name = ":route_name" } = {}) => `/message/${route_name}`,
  // Rewards URLs
  REWARD_ENTRYPOINT: "/rewards",
  // Products URLs
  PRODUCT_ENTRYPOINT: "/products",

  // User Account URLs
  ACCOUNT_SETTINGS: "/my-account/settings",
  ACCOUNT_DELETE: "/my-account/delete-account",
  ACCOUNT_RESET_PASS: "/my-account/reset-pwd",
  ACCOUNT_EDIT_PROFILE: "/my-account/edit-profile",
  ACCOUNT_VIEW_PROFILE: "/my-account/view-profile",
  ACCOUNT_ADDRESS: "/my-account/address",
  ACCOUNT_REFERRAL: "/my-account/referral",
  ACCOUNT_LEADERBOARD: "/my-account/leaderboard",
  ACCOUNT_ACHIEVEMENTS: "/my-account/achievements",
  ACCOUNT_ACTIVITIES: "/my-account/activities",
  ACCOUNT_CONTENT_LIKE: "/my-account/content-you-like",
  ACCOUNT_CONTENT_DISLIKE: "/my-account/content-you-dislike",
  ACCOUNT_CONTENT_WATCHED: "/my-account/already-watched-content",
  ACCOUNT_CONTENT_NOT_INTERESTED: "/my-account/content-marked-not-interested",
  ACCOUNT_MANAGE_PLAYLIST: "/my-account/manage-playlist",
  ACCOUNT_REPORT_BUG: "/my-account/reportbug",
  ACCOUNT_LICENSE: "/my-account/license",
};
