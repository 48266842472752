import { applySnapshot, flow, getParent, getSnapshot, types } from "mobx-state-tree";
import authAPI from "../../api/AuthDev";
import ContentServiceAPI from "../../api/ContentServiceAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";
import { LS_KEY_FOLKS_USER_TOKEN } from "../../utils/constants";
import { toJS } from "mobx";
import { ottAppModel, contentSettingsModel, bundleModel } from "./models/contentSettingsModel";

const contentSettingsStoreModel = {
  contentSettings: types.maybe(contentSettingsModel),
  allOttApps: types.optional(types.array(ottAppModel), []),
  slingBaseBundle: types.maybe(bundleModel),
};

const contentSettingsStoreActions = (self) => ({
  setAllOttApps(data) {
    self.allOttApps = data.filter((item) => item.is_supported);
    self.contentSettings = { ...self.contentSettings, selectottapps: data.filter((item) => item.is_supported) };
  },

  getAppDetailsById(app_id) {
    return (
      self.allOttApps.find((obj) => obj.id === app_id) || {
        // fallback if id is not found.
        id: 0,
        image: "Placeholder Image",
        image_hd: "Placeholder Image",
        is_supported: true,
        title: "This app is not supported.",
      }
    );
  },

  getOttApps: flow(function* getOttApps() {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    try {
      const res = yield ContentServiceAPI.get(__.CONTENT_SERVICE.GET_SUPPORTED_APPS, {
        headers: { token: folksUserToken },
      });
      if (res?.status === 200 && res?.data) {
        self.setAllOttApps(res.data?.data);
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while fetching ott apps!");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  getUserSetting: flow(function* getUserSetting() {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    try {
      const res = yield authAPI.get(__.AUTH_SERVICE.GET_USER_SETTINGS, {
        headers: { token: folksUserToken },
      });
      if (res?.status === 200) {
        if (res?.data?.status) {
          self.contentSettings = res?.data?.user_setting;
        } else {
          self.updateUserSetting({ show_toast: false });
        }

        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while fetching user settings!");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  updateOttChecks: flow(function* updateOttChecks(data) {
    let settingsSnapshot = getSnapshot(self.contentSettings);

    self.contentSettings = {
      ...self.contentSettings,
      selectottapps: toJS(self.allOttApps).filter((item) => data.includes(item?.title)),
    };

    try {
      yield self.updateUserSetting();
      getParent(self).feedsStore.getFolksDeeplinks(); // background call
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  updateShowTitle: flow(function* updateShowTitle(status) {
    let settingsSnapshot = getSnapshot(self.contentSettings);
    self.contentSettings = { ...self.contentSettings, showContentFeedTitle: status };
    try {
      yield self.updateUserSetting();
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  updateShowOtt: flow(function* updateShowOtt(status) {
    let settingsSnapshot = getSnapshot(self.contentSettings);
    self.contentSettings = { ...self.contentSettings, showMediaProviderIconsOnTile: status };
    try {
      yield self.updateUserSetting();
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  updateInstagramFeeds: flow(function* updateInstagramFeeds(status) {
    let settingsSnapshot = getSnapshot(self.contentSettings);
    self.contentSettings = { ...self.contentSettings, showInstagramFeed: status };
    try {
      yield self.updateUserSetting();
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  updateUserSetting: flow(function* updateUserSetting({ show_toast = true } = {}) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    const config = { headers: { token: folksUserToken } };
    const payload = toJS(self.contentSettings);

    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.UPDATE_USER_SETTINGS, payload, config);
      if (res?.status === 200) {
        show_toast && getParent(self).notificationStore.triggerSuccess("User settings updated successfully!");
        return Promise.resolve({ success: true, msg: res?.data?.message });
      } else {
        throw new Error("Something went wrong while updating user settings!");
      }
    } catch (error) {
      show_toast &&
        getParent(self).notificationStore.triggerError("Something went wrong while updating user settings!");
      return Promise.reject(error);
    }
  }),

  updateHasSlingTV: flow(function* updateHasSlingTV(status) {
    let settingsSnapshot = getSnapshot(self.contentSettings);
    self.contentSettings = { ...self.contentSettings, hasSlingTV: status };
    try {
      yield self.updateUserSetting();
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  updateSlingPackageSelection: flow(function* updateSlingPackageSelection({
    base_packages = 3,
    domestic_extras = [],
  } = {}) {
    let settingsSnapshot = getSnapshot(self.contentSettings);

    self.contentSettings = {
      ...self.contentSettings,
      slingSelectedPackages: { base_packages, domestic_extras },
    };

    try {
      yield self.updateUserSetting();
      getParent(self).feedsStore.getSlingShows(); // background call
      return Promise.resolve({ success: true });
    } catch (error) {
      applySnapshot(self.contentSettings, settingsSnapshot);
      return Promise.reject({ success: false });
    }
  }),

  setSlingBundles(bundleObj) {
    try {
      self.slingBaseBundle = bundleObj;
    } catch (e) {
      console.log("Error inserting sling bundle data to MST: ", e);
    }
  },

  getSlingBundles: flow(function* getSlingBundles() {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    try {
      const res = yield ContentServiceAPI.get(__.CONTENT_SERVICE.GET_SLING_BUNDLE_DATA, {
        headers: { token: folksUserToken },
      });
      if (res?.status === 200 && res?.data) {
        self.setSlingBundles(res?.data?.base_packages);
        return Promise.resolve({ success: true, msg: res?.data });
      } else {
        throw new Error("Something went wrong while fetching sling bundle data!");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  loadContentSettings: flow(function* loadProfileData() {
    // Imp. sequential calls.
    try {
      yield self.getOttApps();
    } catch (e) {}

    try {
      yield self.getSlingBundles();
    } catch (e) {}

    try {
      yield self.getUserSetting();
    } catch (e) {}

    return Promise.resolve({ success: true });
  }),
});

const contentSettingsStoreViews = (self) => ({
  get getAllOttAppTitle() {
    return self.allOttApps.map((item) => item?.title);
  },
  get getSelectedOttAppTitle() {
    return self.contentSettings.selectottapps.map((item) => item?.title);
  },
  get getSlingSelectedPackChannelIdArr() {
    return self.slingBaseBundle?.packages
      ?.find((pack) => pack.id === self.contentSettings.slingSelectedPackages.base_packages)
      ?.channels?.map((channel) => channel?.channel_group_id);
  },
  get getSlingSelectedBasePackageName() {
    return self.slingBaseBundle?.packages?.find(
      (pack) => pack.id === self.contentSettings.slingSelectedPackages.base_packages
    )?.title;
  },
  get getSlingBasePackages() {
    return self.slingBaseBundle?.packages.length ? self.slingBaseBundle?.packages : [];
  },
});

export const contentSettingsStore = types
  .model("Profile Store", contentSettingsStoreModel)
  .actions(contentSettingsStoreActions)
  .views(contentSettingsStoreViews);
