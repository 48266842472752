import { flow, types } from "mobx-state-tree";
import auditApi from "../../api/AuditAPI";
import ContentServiceAPI from "../../api/ContentServiceAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";
import { ssReadObj, ssWriteObj } from "../../api/session-cache/sessionCache";
import { ssBasicDetailsKeyGenerator } from "../../api/session-cache/sessionCacheKeys";
import { isImdbId } from "../../utils/helper";

export const AuditStore = types
  .model({
    likedContent: types.optional(types.array(types.string), []),
    disLikedContent: types.optional(types.array(types.string), []),
    notInterestedContent: types.optional(types.array(types.string), []),
    ratedContent: types.optional(types.array(types.string), []),
    seenContent: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setReviewData(data) {
      try {
        self.likedContent = data?.liked_content.filter(isImdbId);
        self.disLikedContent = data?.disliked_content.filter(isImdbId);
        self.notInterestedContent = data?.not_interested_content.filter(isImdbId);
        self.ratedContent = data?.rated_content.filter(isImdbId);
        self.seenContent = data?.seen_content.filter(isImdbId);
      } catch (e) {
        throw new Error(`MST Error: While storing feedback content! \n\n ${e}`);
      }
    },

    filterReviewData(imdb_id) {
      try {
        self.likedContent = self.likedContent.filter((idx) => idx !== imdb_id);
        self.disLikedContent = self.disLikedContent.filter((idx) => idx !== imdb_id);
        self.notInterestedContent = self.notInterestedContent.filter((idx) => idx !== imdb_id);
        self.ratedContent = self.ratedContent.filter((idx) => idx !== imdb_id);
        self.seenContent = self.seenContent.filter((idx) => idx !== imdb_id);
      } catch (e) {
        throw new Error(`MST Error: While storing feedback content! \n\n ${e}`);
      }
    },

    // **ott/show basic details**
    getMoviesBasicDetails: flow(function* getMoviesBasicDetails(imdb_id) {
      // Session storage.
      try {
        const { success, data, message } = ssReadObj(ssBasicDetailsKeyGenerator(imdb_id));
        if (success) {
          return Promise.resolve({ success: true, data: data?.data });
        } else {
          console.info(`Cache miss on getting details for ${imdb_id}! \nReason: ${message} `);
        }
      } catch (e) {
        console.error(e);
      }

      // API call.
      try {
        const res = yield ContentServiceAPI.get(__.CONTENT_SERVICE.GET_BASIC_DETAILS(imdb_id));
        if (res?.status === 200 && res?.data?.data) {
          ssWriteObj(ssBasicDetailsKeyGenerator(imdb_id), { data: res?.data?.data });
          return Promise.resolve({ success: true, data: res?.data?.data });
        } else {
          throw new Error("Something went wrong while fetching basic details!");
        }
      } catch (error) {
        self.filterReviewData(imdb_id);
        return Promise.reject(error);
      }
    }),

    updateLikedContent(id, action) {
      if (action === "remove_likeContent") {
        self.likedContent = self.likedContent.filter((idx) => idx !== id);
      }
      if (action === "add_likeContent") {
        self.likedContent.unshift(id);
      }
    },
    updateDisLikeContent(id, action) {
      if (action === "remove_disLikeContent") {
        self.disLikedContent = self.disLikedContent.filter((idx) => idx !== id);
      }
      if (action === "add_disLikeContent") {
        self.disLikedContent.unshift(id);
      }
    },
    updateSeenContent(id, action) {
      if (action === "remove_seenContent") {
        self.seenContent = self.seenContent.filter((idx) => idx !== id);
      }
      if (action === "add_seenContent") {
        self.seenContent.unshift(id);
      }
    },
    updateNotInterestedContent(id, action) {
      if (action === "remove_notInterestedContent") {
        self.notInterestedContent = self.notInterestedContent.filter((idx) => idx !== id);
      }
      if (action === "add_notInterestedContent") {
        self.notInterestedContent.unshift(id);
      }
    },

    fetchSocialFeed: flow(function* fetchSocialFeed(imdb_id, skip, limit) {
      try {
        const res = yield auditApi.get(__.AUDIT_SERVICE.GET_FRIENDS_ACTIVITY({ imdb_id, skip, limit }));
        if (res?.status === 200) {
          return Promise.resolve({ success: true, data: res?.data });
        } else {
          throw new Error("Bad response from server!");
        }
      } catch (error) {
        return Promise.reject({
          success: false,
          message: error?.response?.data?.message || error?.message || "Something went wrong!",
        });
      }
    }),

    fetchFeedbackContent: flow(function* fetchFeedbackContent() {
      try {
        const res = yield auditApi.get(__.AUDIT_SERVICE.GET_USER_FEEDBACK_CONTENT);
        if (res?.status === 200 && res?.data) {
          self.setReviewData(res.data);
          return Promise.resolve({ success: true, data: res.data });
        } else {
          throw new Error("Something went wrong while fetching feedback Content!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),
  }))
  .views((self) => ({}));
