import { Modal } from "antd";
import styled from "styled-components";

const FolksModal = styled(Modal).attrs(({ width, centered, zIndex }) => ({
  footer: null,
  width: width || "35vw",
  centered: centered || false,
  zIndex: zIndex || 1000,
}))`
  &&& {
    .ant-modal-body {
      padding: 2vw;
    }
    .ant-modal-content {
      background-color: #1a1a1c;
      border-radius: 1vw;
      margin-top: 2.5vw;
    }
    .ant-modal-close-x {
      height: 3vw;
      width: 3vw;
      line-height: 3vw;
      font-size: 1vw;
    }
  }
`;

export default FolksModal;
