const LS_MAX_CACHE_AGE_HOURS = 48;
const MAX_OBJECT_SIZE_BYTES = 5242880; // 5MiB

function isExpired(receivedTime, validHours = LS_MAX_CACHE_AGE_HOURS) {
  let currentDate = new Date();
  let lastUpdated = new Date(receivedTime);
  let timeDifference = (currentDate.getTime() - lastUpdated.getTime()) / 1000 / 60 / 60;
  if (timeDifference > validHours) {
    return true;
  } else {
    return false;
  }
}

export const lsReadObj = (key) => {
  const obj = JSON.parse(localStorage.getItem(`${key}`));
  if (obj) {
    if (typeof obj === "object") {
      if (!isExpired(obj?.timestamp)) {
        return { success: true, data: obj, message: "Success!" };
      } else {
        return { success: false, data: null, message: "Error:  data is expired!" };
      }
    } else {
      return { success: false, data: null, message: "Error:  datatype is not object " };
    }
  } else {
    return { success: false, data: null, message: "Error: Object missing" };
  }
};

export const lsWriteObj = (key, data) => {
  try {
    if (data) {
      if (typeof data === "object") {
        if (Buffer.from(JSON.stringify(data)).length <= MAX_OBJECT_SIZE_BYTES) {
          // overwrites the timestamp property if already exists!
          data.timestamp = new Date().toString();
          localStorage.setItem(`${key}`, JSON.stringify(data));
          return { success: true };
        } else {
          throw new Error("Object exceeds max allowable size.");
        }
      } else {
        throw new Error("Datatype is not object!");
      }
    } else {
      throw new Error("Object missing!");
    }
  } catch (err) {
    if (err.code === "22" || err.code === "1024") {
      return { success: false, message: "Error: LS Quota exceeded!" };
    } else {
      return { success: false, message: `Error: ${err.message}` };
    }
  }
};

export const lsDeleteObj = (key) => {
  if (localStorage.getItem(`${key}`)) {
    localStorage.removeItem(`${key}`);
    return { success: true };
  } else {
    return { success: false, message: "Error: Key does not exist!" };
  }
};
