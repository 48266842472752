// Info:
// titles: Text displayed on the screen
// id: used to identify card and add/remove selective attributes while rendering.
// action: what card is supposed to do
// action_detail: short info. (tooltip)
// path: Required when action = GOTO_PATH
// logo: react-iocns logo for each option

import { AiOutlineSync, AiOutlineBug } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { MdOutlineLocationOn, MdLocalLibrary } from "react-icons/md";
import { RiContactsBookLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { BiShieldQuarter } from "react-icons/bi";
import { MdOutlineSecurityUpdateWarning } from "react-icons/md";
import { URLS } from "../../routes/routeURL";

export const navCardData = [
  {
    title: "Profile",
    id: "profile",
    action: "GOTO_PATH",
    action_detail: "Open edit profile page",
    path: URLS.ACCOUNT_EDIT_PROFILE,
    logo: <CgProfile />,
    submenu: [
      {
        title: "Edit profile",
        id: "edit_profile",
        action: "GOTO_PATH",
        action_detail: "Open edit profile page",
        path: URLS.ACCOUNT_EDIT_PROFILE,
      },
      {
        title: "View profile",
        id: "view_profile",
        action: "GOTO_PATH",
        action_detail: "Open view profile page",
        path: URLS.ACCOUNT_VIEW_PROFILE,
      },
    ],
  },
  {
    title: "Refer & Earn",
    id: "referral",
    action: "TOGGLE_MENU",
    action_detail: "Show account referral page",
    path: URLS.ACCOUNT_REFERRAL,
    logo: <RiContactsBookLine />,
    submenu: [
      {
        title: "How it works",
        id: "how_it_works",
        action: "GOTO_PATH",
        action_detail: "Show account referral page",
        path: URLS.ACCOUNT_REFERRAL,
      },
      {
        title: "Leaderboard",
        id: "leaderboard",
        action: "GOTO_PATH",
        action_detail: "Show account referral page",
        path: URLS.ACCOUNT_LEADERBOARD,
      },
      {
        title: "Activities",
        id: "activities",
        action: "GOTO_PATH",
        action_detail: "Show account referral page",
        path: URLS.ACCOUNT_ACTIVITIES,
      },
      {
        title: "Achievements",
        id: "achievements",
        action: "GOTO_PATH",
        action_detail: "Show Achievements",
        path: URLS.ACCOUNT_ACHIEVEMENTS,
      },
    ],
  },
  {
    title: "Manage Library",
    id: "manage_library",
    action: "TOGGLE_MENU",
    action_detail: "Show account manage library page",
    path: URLS.ACCOUNT_CONTENT_LIKE,
    logo: <MdLocalLibrary />,
    submenu: [
      {
        title: "Content you like",
        id: "content_you_like",
        action: "GOTO_PATH",
        action_detail: "Show account manage library page",
        path: URLS.ACCOUNT_CONTENT_LIKE,
      },
      {
        title: "Content you dislike",
        id: "content_you_dislike",
        action: "GOTO_PATH",
        action_detail: "Show account manage library page",
        path: URLS.ACCOUNT_CONTENT_DISLIKE,
      },
      {
        title: "Already watched content",
        id: "already_see_content",
        action: "GOTO_PATH",
        action_detail: "Show account manage library page",
        path: URLS.ACCOUNT_CONTENT_WATCHED,
      },
      {
        title: "Content marked not interested",
        id: "content_marked_not_interested",
        action: "GOTO_PATH",
        action_detail: "Show account manage library page",
        path: URLS.ACCOUNT_CONTENT_NOT_INTERESTED,
      },
      // {
      //   title: "Playlist",
      //   id: "manage_playlist",
      //   action: "GOTO_PATH",
      //   action_detail: "Show Manage Playlist Page",
      //   path: URLS.ACCOUNT_MANAGE_PLAYLIST,
      // },
    ],
  },
  // {
  //   title: "Addresses",
  //   id: "addresses",
  //   action: "GOTO_PATH",
  //   action_detail: "Show existing addresses.",
  //   path: URLS.ACCOUNT_ADDRESS,
  //   logo: <MdOutlineLocationOn />,
  // },
  {
    title: "Settings",
    id: "settings",
    action: "GOTO_PATH",
    action_detail: "Show account settings page",
    path: URLS.ACCOUNT_SETTINGS,
    logo: <FiSettings />,
  },
  {
    title: "Account & Security",
    id: "account_security",
    action: "TOGGLE_MENU",
    action_detail: "Show account setting and security page",
    logo: <BiShieldQuarter />,
    submenu: [
      {
        title: "Reset Password",
        id: "reset_password",
        action: "GOTO_PATH",
        action_detail: "Open reset password screen",
        path: URLS.ACCOUNT_RESET_PASS,
        logo: <AiOutlineSync />,
      },
      {
        title: "Delete Account",
        id: "delete_account",
        action: "GOTO_PATH",
        action_detail: "delete User account",
        path: URLS.ACCOUNT_DELETE,
        logo: <FiSettings />,
      },
    ],
  },
  {
    title: "About",
    id: "about",
    action: "GOTO_PATH",
    action_detail: "Open about screen",
    logo: <MdOutlineSecurityUpdateWarning />,
    submenu: [
      {
        title: "License",
        id: "license",
        action: "GOTO_PATH",
        action_detail: "Open licences screen",
        path: URLS.ACCOUNT_LICENSE,
      },
      {
        title: "Terms of use",
        id: "terms_of_use",
        action: "GOTO_PATH",
        action_detail: "Open terms of use screen",
        path: URLS.TERMS_OF_USE,
      },
      {
        title: "Privacy Policy",
        id: "privacy_policy",
        action: "GOTO_PATH",
        action_detail: "Open privacy policy screen",
        path: URLS.PRIVACY_POLICY,
      },
    ],
  },
  // {
  //   title: "Report Bugs",
  //   id: "report_bugs",
  //   action: "GOTO_PATH",
  //   action_detail: "Open bug report screen",
  //   path: URLS.ACCOUNT_REPORT_BUG,
  //   logo: <AiOutlineBug />,
  // },
];

export const guestNavCardData = [
  {
    title: "Settings",
    id: "settings",
    action: "GOTO_PATH",
    action_detail: "Show account settings page",
    path: URLS.ACCOUNT_SETTINGS,
    logo: <FiSettings />,
  },
  {
    title: "About",
    id: "about",
    action: "GOTO_PATH",
    action_detail: "Open about screen",
    logo: <MdOutlineSecurityUpdateWarning />,
    submenu: [
      {
        title: "License",
        id: "license",
        action: "GOTO_PATH",
        action_detail: "Open licences screen",
        path: URLS.ACCOUNT_LICENSE,
      },
      {
        title: "Terms of use",
        id: "terms_of_use",
        action: "GOTO_PATH",
        action_detail: "Open terms of use screen",
        path: URLS.TERMS_OF_USE,
      },
      {
        title: "Privacy Policy",
        id: "privacy_policy",
        action: "GOTO_PATH",
        action_detail: "Open privacy policy screen",
        path: URLS.PRIVACY_POLICY,
      },
    ],
  },
  // {
  //   title: "Report Bugs",
  //   id: "report_bugs",
  //   action: "GOTO_PATH",
  //   action_detail: "Open bug report screen",
  //   path: URLS.ACCOUNT_REPORT_BUG,
  //   logo: <AiOutlineBug />,
  // },
];
