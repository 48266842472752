import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useMst } from "../../store";
import { accountRoutes } from "../../routes/routes";
import { REDIRECTION_ROUTES } from "../../utils/redirectionSettings";
import { navCardData } from "./NavData";
import { guestNavCardData } from "./NavData";

import { Layout, Menu } from "antd";
import { AiOutlineLogout, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { toast } from "react-toastify";
import styled from "styled-components";

import FolksModal from "../../components/folks-modal/FolksModal";
import FolksAvatar from "../../components/folks-avatar/FolksAvatar";
import { ModalHeadingText } from "../../components/typography/ModalText";
import { ModalFooterButton } from "../../components/folks-modal/ModalFooterButton";

// CSS imports
import styles from "./accountsSidebar.module.css";

const { Sider } = Layout;

const SiderContainer = styled(Sider)`
  &&& {
    min-width: 17vw !important;
    background: #222126;
    overflow-x: hidden;
    height: 100vh;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const MenuContainer = styled.div`
  /* override antd classes */
  .ant-menu {
    background: #222126;
    border: none;
    font-size: 1.1vw;
    font-weight: 600;
  }

  .ant-menu .ant-menu-item {
    padding: 1.7vw 0.5vw;

    &:hover {
      color: #b7b7b7;
    }
  }

  .ant-menu-item-icon {
    font-size: 1.4vw;
    margin-right: 0.5vw;
  }

  .ant-menu .ant-menu-item-selected {
    background: linear-gradient(0deg, #080e14, #080e14), #1a1a1a;
    border-left: 0.4vw solid #fc5252;
  }

  .ant-menu-item a:hover {
    color: #fff;
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a {
    color: #fff;

    &:after {
      display: none;
      visibility: hidden;
    }
  }

  /* sub menu css */

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #b7b7b7;
    height: 3.6vw;

    &:hover {
      color: #fff;
    }
  }

  .ant-menu-submenu .ant-menu .ant-menu-item-selected {
    border: none;
    background: none;
  }

  .ant-menu .ant-menu-sub {
    background: rgba(255, 255, 255, 0.05);
  }

  .ant-menu-submenu-selected > div {
    border-left: 0.4vw solid #fc5252;
    padding: 1.7vw 1.5vw;
    background: #080e14;
  }
`;

const AccountsSidebar = () => {
  const { auth, profileStore } = useMst();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const location = useLocation();
  const handleAccountLogout = () => {
    setIsModalVisible(true);
  };

  const siderMenuItem = Object.values(accountRoutes)?.find((item) => {
    return item.path === location.pathname && item.id;
  });

  const handleOk = async () => {
    setIsModalVisible(false);
    try {
      await auth.logout();
      window.location.href = REDIRECTION_ROUTES.ACC_SIDER_LOGOUT;
    } catch (e) {
      toast.error("Error logging out!", e);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const siderDataMenu = (auth.isGuestUser ? guestNavCardData : navCardData).map((item) => {
    if (
      item.id === "referral" ||
      item.id === "manage_library" ||
      item.id === "profile" ||
      item.id === "about" ||
      item.id === "account_security"
    ) {
      return {
        label: `${item?.title}`,
        key: `${item?.id}`,
        icon: item?.logo,
        children: item.submenu.map((item) => {
          return { label: <Link to={item.path}>{item.title}</Link>, key: `${item?.id}` };
        }),
      };
    } else {
      return {
        label: <Link to={item.path}>{item.title}</Link>,
        key: `${item?.id}`,
        icon: item?.logo,
      };
    }
  });

  return (
    <SiderContainer>
      <div className={styles.detailsContainer}>
        {auth.isNormalUser ? (
          <div>
            <div className={styles.profileImage}>
              <FolksAvatar
                name={profileStore?.getUserName || ""}
                round={true}
                size="5vw"
                src={profileStore?.getAvatarImageUrl || ""}
              />
            </div>
            <div className={styles.details}>
              <div className={styles.nameContainer}>
                <span style={{ fontSize: "1vw", marginRight: "5px" }}>Hello,</span>
                <span className={styles.name}>{profileStore?.getUserName || ""}</span>
              </div>

              <div className={styles.nameContainer}>
                <p className={styles.email}>{profileStore?.getEmail || ""}</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <MenuContainer>
        <Menu
          defaultSelectedKeys={siderMenuItem?.id}
          mode="inline"
          items={siderDataMenu}
          expandIcon={(event) => {
            return event.isOpen ? <AiOutlineUp /> : <AiOutlineDown />;
          }}
        ></Menu>
      </MenuContainer>
      {auth.isNormalUser ? (
        <div>
          <div onClick={handleAccountLogout} className={styles.logout}>
            <AiOutlineLogout className={styles.logoutLogo} />
            <span>Logout</span>
          </div>
        </div>
      ) : null}

      <div className={styles.versionDescriber}>
        {process.env.REACT_APP_VERSION || process.env.REACT_APP_WEBSITE_NAME}
      </div>

      <FolksModal visible={isModalVisible} onCancel={handleCancel}>
        <div className={styles.modalContainer}>
          <div>
            <ModalHeadingText>Are you sure you want to logout?</ModalHeadingText>
            <div className={styles.modalFooterContainer}>
              <div>
                <ModalFooterButton className={styles.footerBtn} color="#A6A6A6" onClick={handleCancel}>
                  No
                </ModalFooterButton>
                <ModalFooterButton className={styles.footerBtn} color="#289E5E" onClick={handleOk}>
                  Yes
                </ModalFooterButton>
              </div>
            </div>
          </div>
        </div>
      </FolksModal>
    </SiderContainer>
  );
};

export default observer(AccountsSidebar);
