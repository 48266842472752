// Auth Service:
const AUTH_SERVICE = {
  // authStore.js
  WEB_USER_LOGIN: "/web_user_login/",
  UPDATE_USER_PASSWORD: "/update_user_password/",
  FORGOT_PASSWORD: "/forgot_password/",
  CONFIRM_FORGOT_PASSWORD: "/confirm_forgot_password/",
  DELETE_USER_ACC: "/notification/email",
  GUEST_USER_LOGIN: "/guest_user_login",
  // referralStore.js
  GET_REFERRAL_CODE: (user_id) => `/get_user_referral_code/${user_id}`,
  // contentSettingsStore.js
  GET_USER_SETTINGS: "/get-user-setting/",
  UPDATE_USER_SETTINGS: "/update-user-setting/",
  // profileStore.js
  GET_USER_PROFILE: (user_id) => `/get_user_profile/${user_id}`,
  UPDATE_USER_NAME: "/update_username/",
  UPDATE_USER_PROFILE: "/update_user_profile/",
  UPDATE_DOB: "/update_date_of_birth/",
  UPDATE_GENDER: "/update_gender/",
  UPDATE_PROFILE_PIC: "/upload_profile_pic/",
  ADD_ADDRESS: "/address/post_address_with_token",
  GET_ALL_ADDRESSES: (user_id) => `/address/list_all_address/${user_id}`,
  UPDATE_ADDRESS: (address_id) => `/address/update_address/${address_id}`,
  DELETE_ADDRESS: (address_id) => `/address/remove_address/${address_id}`,
};

// Audit Service:
const AUDIT_SERVICE = {
  // auditStore.js
  GET_FRIENDS_ACTIVITY: ({ imdb_id, skip = 0, limit = 10 } = {}) =>
    `/api/v1/social-feeds/${imdb_id}?source=imdb&skip=${skip}&limit=${limit}`,
  GET_USER_FEEDBACK_CONTENT: "api/v1/user-feedback-content",
};

// Content Service Endpoint Validators:
const _PROFILE_SERVICES = ["all", "playlist", "content-review"];
const _isValidProfileService = (service_str) => {
  if (_PROFILE_SERVICES.includes(service_str)) {
    return true;
  }
  return false;
};

// Content Service:
const CONTENT_SERVICE = {
  // feedsStore.js
  // GET_BASKET_CONTENT: "/basket/get_basket_content_web/v1",
  CONTENT_META: "/meta/content-meta",
  GET_BASKET_CONTENT: "/basket/get_basket_content/v4",
  GET_GENRE_LIST: "/genre/genre_list",
  GET_EMOTION_CONTENT: (emotion_value) => `/content/get_emotion_content_web/${emotion_value}`,
  GET_SLING_SCHEDULE: ({ time_zone = "America-Los_Angeles", utc_time = "time-placeholder" } = {}) =>
    `/sling/v3/schedule_data/${time_zone}/${utc_time}`,
  GET_SLING_BUNDLE_DATA: "/sling/sing_bundle_data",
  // auditStore.js
  GET_BASIC_DETAILS: (imdb_id) => `movie/basic_details/${imdb_id}`,
  // contentSettingsStore.js
  GET_SUPPORTED_APPS: "/content/supported_apps",
  // playlistStore.js
  CREATE_PLAYLIST: "/watchlist/v1/create",
  READ_PLAYLISTS: ({ limit = 10, skip = 0 } = {}) => `/watchlist/v2/all_watchlists?limit=${limit}&skip=${skip}`,
  UPDATE_PLAYLIST: (playlist_id) => `/watchlist/update/${playlist_id}`,
  DELETE_PLAYLIST: (playlist_id) => `/watchlist/delete/${playlist_id}`,
  SUBSCRIBE_PLAYLIST: (playlist_id) => `/watchlist/v1/subscribe/${playlist_id}`,
  READ_PLAYLIST_CONTENT: (playlist_id) => `/watchlist/get_watchlist_content/${playlist_id}`,
  UPDATE_PLAYLIST_CONTENT: "/watchlist/update_movie_in_watchlists",
  DELETE_PLAYLIST_CONTENT: (playlist_id) => `/watchlist/delete_movies/${playlist_id}`,
  READ_PLAYLIST_IDS_FOR_CONTENT: (imdb_id) => `/watchlist/user_watchlist_by_imdb_id/${imdb_id}`,
  // searchStore.js
  SEARCH_PLAYLISTS: (search_str) => `/watchlist/v1/watchlist_search/${search_str}`,
  // profileStore.js, publicProfileStore.js
  GET_PROFILE_DATA: ({ user_id = null, service = null, limit = 10, skip = 0 } = {}) =>
    `/profile/public-user-profile?${_isValidProfileService(service) ? `service=${service}` : ""}${
      user_id ? `&user_id=${user_id}` : ""
    }&limit=${limit}&skip=${skip}`,
};

// Payment Service:
const PAYMENT_SERVICE = {
  // paymentsStore.js
  ADD_CARD: "/api/v1/rest/add-card",
  ADD_PAYMENT_METHOD: "/api/v1/rest/associate-payment-method",
};

// Product Service:
const PRODUCT_SERVICE = {
  // feedsStore.js
  GET_BANNERS: "/v1/content_banner/",
};

// Referral Service:
const REFERRAL_SERVICE = {
  // referralStore.js
  VAL_REF_CODE: (referral_code_str) => `/api/v1/rest/validate-referral-code?referral_code=${referral_code_str}`,
};

// Review Service:
const REVIEW_SERVICE = {
  // actionStore.js
  GET_ALLOWED_EMOTIONS: ({ landing = true } = {}) => `/api/v1/rest/fetch-emotions?landing=${!!landing}`,
  GET_ALL_REACTIONS: (imdb_id) => `/api/v1/rest/fetch-user-reaction/${imdb_id}?source=imdb`,

  GET_LIKE_DATA: (imdb_id) => `/api/v1/rest/fetch-likes-data/${imdb_id}?source=imdb`,
  UPDATE_LIKE_DATA: "/api/v2/rest/add-like-dislike",

  GET_RATING: (imdb_id) => `/api/v1/rest/fetch-user-rating/${imdb_id}?source=imdb`,
  UPDATE_RATING: "/api/v1/rest/add-user-rating",

  GET_IS_SEEN: (imdb_id) => `/api/v1/rest/fetch-user-seen/${imdb_id}?source=imdb`,
  UPDATE_IS_SEEN: "/api/v1/rest/add-user-seen",

  GET_EMOTION: (imdb_id) => `/api/v1/rest/fetch-user-emotion/${imdb_id}?source=imdb`,
  UPDATE_EMOTION: "/api/v1/rest/add-user-emotion",
  GET_TOP_EMOTIONS: (imdb_id) => `/api/v1/rest/top-emotions/${imdb_id}`,

  GET_NOT_INTERESTED: (imdb_id) => `/api/v1/rest/fetch-no-interest-reason/${imdb_id}`,
  UPDATE_NOT_INTERESTED: "/api/v1/rest/mark-not-interested",
  DELETE_NOT_INTERESTED: (object_id) => `/api/v1/rest/remove-not-interested/${object_id}`,

  GET_ALL_REVIEWS: ({ imdb_id, skip = 0, limit = 10 } = {}) =>
    `/api/v1/rest/reviews/${imdb_id}?source=imdb&skip=${skip}&limit=${limit}`,
  REVIEW_MARK_HELPFUL: "/api/v1/rest/mark-helpful",
  REVIEW_MARK_ABUSE: "/api/v1/rest/report-abuse",
  REVIEW_REMOVE_ABUSE: (object_id) => `/api/v1/rest/delete-report-abuse/${object_id}`,

  GET_USER_REVIEW: (imdb_id) => `/api/v1/rest/fetch-user-review/${imdb_id}?source=imdb`,
  ADD_USER_REVIEW: "/api/v1/rest/add-user-review",
  DELETE_USER_REVIEW: (object_id) => `/api/v1/rest/delete-user-review/${object_id}`,
};

// Rewards Service:
const REWARDS_SERVICE = {
  // rewardsStore.js
  GET_USER_ACHIEVEMENTS: "/api/apk/v2/referral/leaderboard/achievements",
  GET_USER_ACTIVITY: ({ skip = 0, limit = 10 } = {}) =>
    `/api/apk/v1/referral/user_activity?page=${skip}&limit=${limit}`,
  GET_LEADERBOARD_DATA: "/api/apk/v1/referral/leaderboard",
  GET_EARN_REWARD_ACTIONS: "/api/apk/v1/earn_reward_actions",
  GET_DASHBOARD_DATA: ({ tier = 5 } = {}) => `/api/apk/v1/dashboard?teir=${tier}`,
  GET_ACTIONS: "/api/v1/actions",
  GET_TOKEN_EARNINGS: ({ lmp = false, lmup = false, cat = true, months = 6, teir = 5 } = {}) =>
    `/api/v1/reports/token_earnings?last_month_potential=${lmp}&months_number=${months}&last_month_Unearned_potential=${lmup}&category=${cat}&teir=${teir}`,
  GET_CHART_DISTRIBUTION: ({ lmp = false, lmup = false, cat = true, months = 6 } = {}) =>
    `/api/v1/reports/token_earnings?last_month_potential=${lmp}&months_number=${months}&last_month_Unearned_potential=${lmup}&category=${cat}`,
  GET_REFFERAL_EARNINGS: ({ pe = false, ee = false, details = true } = {}) =>
    `/api/v1/earning?potential_earning=${pe}&details=${details}&estimated_earning=${ee}`,
};

// Search Service:
const SEARCH_SERVICE = {
  // mdpStore.js
  GENRE_DETAILS: "/genre/genre_content/v4/",
  PERSON_DETAILS: "/api/v2/people_content",
  MEDIA_DETAILS: "/api/v2/media_details",
  // searchStore.js
  GET_SUGGESTIONS: (search_str) => `/api/v2/suggest?query=${search_str}`,
  GET_RESULTS: "/api/v2/search",
};

// Video Review Service:
const VIDEO_REVIEW_SERVICE = {
  // actionStore.js
  GET_DATA: (video_id) => `/api/v1/video_reviews/${video_id}`,
  ADD_LIKE: "/api/v1/video_like_dislike/",
  REPORT: "/api/v1/video_report/",
};

const NOTIFICATION_SERVICE = {
  RECEIVE_REGISTRATION_TOKEN: "/api/v1/receive_registration_token",
};

export const EP_ROOT = {
  AUTH_SERVICE,
  AUDIT_SERVICE,
  CONTENT_SERVICE,
  PAYMENT_SERVICE,
  PRODUCT_SERVICE,
  REFERRAL_SERVICE,
  REVIEW_SERVICE,
  REWARDS_SERVICE,
  SEARCH_SERVICE,
  VIDEO_REVIEW_SERVICE,
  NOTIFICATION_SERVICE,
};
