import makeInspectable from "mobx-devtools-mst";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider, store as rootStore } from "./store";
import { restrictedUserRoutePathList } from "./routes/routes";

// CSS imports [Scope => Global]
// Main style
import "./assets/styles/main.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Ant design dark
import "antd/dist/antd.dark.css";
// React slick (sliding media rows)
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// React toastify
import "react-toastify/dist/ReactToastify.css";

makeInspectable(rootStore);

const params = new URLSearchParams(window.location.search);
const referralCode = params.get("ref");

ReactDOM.render(
  <Provider value={rootStore}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// if (
//   !rootStore.browserStore.isMobileUser ||
//   (rootStore.browserStore.isMobileUser && restrictedUserRoutePathList.includes(window.location.pathname))
// ) {
//   ReactDOM.render(
//     <Provider value={rootStore}>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </Provider>,
//     document.getElementById("root")
//   );
// } else {
//   // If route is not allowed for mobile user, redirect to the this location.
//   window.location = `https://go.folksmedia.com?apn=com.folksmediaapp&ibi=com.adcuratio.folksmedia&isi=1579558800&link=${encodeURIComponent(
//     `https://app.folksmedia.com${window.location.pathname}${referralCode ? `?ref=${referralCode}` : ""}`
//   )}`;
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
