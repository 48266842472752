import { URLS } from "../routes/routeURL";

export const guestHeader = {
  left: [
    {
      item_type: "tab",
      item_id: "home",
      title: "Home",
      path: URLS.CONTENT_HOME,
    },
    {
      item_type: "tab",
      item_id: "shows",
      title: "TV Shows",
      path: URLS.CONTENT_SHOWS,
    },
    {
      item_type: "tab",
      item_id: "movies",
      title: "Movies",
      path: URLS.CONTENT_MOVIES,
    },
    // {
    //   item_type: "tab",
    //   item_id: "my-list",
    //   title: "My List",
    //   path: URLS.CONTENT_HOME,
    // },
  ],
  center: [],
  right: [
    {
      item_type: "tab",
      item_id: "search",
      title: "Search",
      path: URLS.CONTENT_SEARCH,
    },
    {
      item_type: "tab",
      item_id: "guest-account",
      title: "Guest Account",
      path: URLS.ACCOUNT_SETTINGS,
    },
    // {
    //   item_type: "tab",
    //   item_id: "rewards",
    //   title: "Rewards",
    //   path: URLS.REWARD_ENTRYPOINT,
    // },
    // {
    //   item_type: "tab",
    //   item_id: "log-in",
    //   title: "Login",
    //   path: URLS.LOGIN,
    // },
    {
      item_type: "tab",
      item_id: "sign-up",
      title: "Sign Up",
      path: URLS.REGISTER,
    },
  ],
};

export const familyHeader = {
  left: [
    {
      item_type: "tab",
      item_id: "home",
      title: "Home",
      path: URLS.CONTENT_HOME,
    },
    {
      item_type: "tab",
      item_id: "shows",
      title: "TV Shows",
      path: URLS.CONTENT_SHOWS,
    },
    {
      item_type: "tab",
      item_id: "movies",
      title: "Movies",
      path: URLS.CONTENT_MOVIES,
    },
    // {
    //   item_type: "tab",
    //   item_id: "my-list",
    //   title: "My List",
    //   path: URLS.CONTENT_HOME,
    // },
  ],
  center: [],
  right: [
    {
      item_type: "tab",
      item_id: "search",
      title: "Search",
      path: URLS.CONTENT_SEARCH,
    },
    // {
    //   item_type: "tab",
    //   item_id: "rewards",
    //   title: "Rewards",
    //   path: URLS.REWARD_ENTRYPOINT,
    // },
    // {
    //   item_type: "tab",
    //   item_id: "products",
    //   title: "Products",
    //   path: URLS.PRODUCT_ENTRYPOINT,
    // },
    {
      item_type: "tab",
      item_id: "my-account",
      title: "My Profile",
      path: URLS.ACCOUNT_EDIT_PROFILE,
    },
  ],
};
