/*eslint-disable*/
import { Auth } from "aws-amplify";
import { flow, getParent, types } from "mobx-state-tree";
import { toJS } from "mobx";
import { jwtDecode } from "../../utils/helper";

import authAPI from "../../api/AuthDev";
import { EP_ROOT as __ } from "../../utils/endpoints";

import {
  LS_KEY_COGNITO_ID_TOKEN,
  LS_KEY_FOLKS_USER_TOKEN,
  LS_KEY_FOLKS_USER_TYPE,
  LS_KEY_FOLKS_USER_ID,
} from "../../utils/constants";

import { USER_TYPE, allowedUserTypes } from "./models/authModel";

const AuthStoreModel = {
  cognitoIdToken: "",
  folksUserToken: "",
  folksUserType: "",
  folksUserId: "", // Derived from folksUserToken
};

const AuthStoreActions = (self) => ({
  setUserType(userType) {
    if (allowedUserTypes.includes(userType)) self.folksUserType = userType;
  },

  login: flow(function* login(email, password) {
    console.log("logging in !!");
    yield self.logout(); // Old user data wipe.

    // 1. Cognito service login.
    try {
      const cognitoLoginRes = yield Auth.signIn({ username: email, password });
      self.cognitoIdToken = cognitoLoginRes?.signInUserSession?.idToken?.jwtToken || "";
      localStorage.setItem(LS_KEY_COGNITO_ID_TOKEN, cognitoLoginRes?.signInUserSession?.idToken?.jwtToken || "");
    } catch (error) {
      return Promise.reject({
        success: false,
        error: error.message || "Something went wrong while logging in!",
      });
    }

    // 2. Fetch third party tokens.
    try {
      const webUserLoginRes = yield authAPI.post(__.AUTH_SERVICE.WEB_USER_LOGIN, {
        cognito_token: self.cognitoIdToken,
      });
      if (webUserLoginRes?.status === 200 && !!webUserLoginRes?.data) {
        const decodedToken = jwtDecode(webUserLoginRes?.data?.adcuratio_token);

        self.folksUserToken = webUserLoginRes?.data?.adcuratio_token || "";
        self.folksUserId = decodedToken?.data?.user_id || "";
        self.setUserType(USER_TYPE.NORMAL);

        localStorage.setItem(LS_KEY_FOLKS_USER_TOKEN, webUserLoginRes?.data?.adcuratio_token || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_ID, decodedToken?.data?.user_id || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_TYPE, USER_TYPE.NORMAL);
      } else {
        throw new Error("Something went wrong while logging in Web user!");
      }
    } catch (error) {
      return Promise.reject({ success: false, error: error });
    }

    // 3. token association with firebase .
    try {
      yield getParent(self).notificationStore.sendDeviceToken({ is_delete: false });
    } catch (error) {}

    return Promise.resolve({ success: true });
  }),

  guestLogin: flow(function* guestLogin() {
    console.log("guest user log in !!");
    // Fetch guest user token.
    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.GUEST_USER_LOGIN);
      if (res?.status === 200 && res?.data?.adcuratio_token) {
        const decodedToken = jwtDecode(res?.data?.adcuratio_token);

        self.folksUserToken = res?.data?.adcuratio_token || "";
        self.folksUserId = decodedToken?.data?.guest_id || "";
        self.setUserType(USER_TYPE.GUEST);

        localStorage.setItem(LS_KEY_FOLKS_USER_TOKEN, res?.data?.adcuratio_token || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_ID, decodedToken?.data?.guest_id || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_TYPE, USER_TYPE.GUEST);
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while logging in guest user!");
      }
    } catch (error) {
      return Promise.reject({ success: false, error: error });
    }
  }),

  // On reset and forgot password (do global logout).
  logout: flow(function* logout({ global = false } = {}) {
    console.log("logging out !!");
    const userType = toJS(self.folksUserType);

    // 1. Detach notification token.
    try {
      yield getParent(self).notificationStore.sendDeviceToken({ is_delete: true });
    } catch (error) {}

    // 2. Clear storage and reset all stores
    sessionStorage.clear();
    localStorage.clear();
    getParent(self).reset();

    // 3. Cognito sign-out (if applicable)
    try {
      if (userType === USER_TYPE.NORMAL) yield Auth.signOut(global);
    } catch (e) {
      console.log("Cognito logout error: ", e);
    }

    return Promise.resolve({ success: true });
  }),

  // On reset and forgot password (do global logout).
  logoutWithGuestReEntry: flow(function* logoutWithGuestReEntry({ global = false } = {}) {
    try {
      yield self.logout({ global });
      yield self.guestLogin();
      return Promise.resolve({ success: true });
    } catch (e) {
      console.log("logout to guest error: ", e);
      return Promise.reject({ success: false, error: `Error guest re-entry: ${e}` });
    }
  }),

  isLoggedIn: flow(function* isLoggedIn() {
    console.log("login check !!");
    const userToken = self.folksUserToken;
    const userType = self.folksUserType;

    // 1. User token present?
    if (!userToken) {
      return Promise.reject({ success: false, error: "Folks user token not present!" });
    }

    // 2. User type present?
    if (!userType) {
      return Promise.reject({ success: false, error: "Folks user type is not defined!" });
    }

    // 3. User type == none?
    if (userType === USER_TYPE.NONE) {
      return Promise.reject({ success: false, error: "Folks user not logged in!" });
    }

    // 4. User type recognized?
    if (!Object.values(USER_TYPE).includes(userType)) {
      return Promise.reject({ success: false, error: "Folks user type is not recognized!" });
    }

    // 5. Cognito login present for normal user?
    if (userType === USER_TYPE.NORMAL) {
      try {
        yield Auth.currentAuthenticatedUser();
      } catch (error) {
        return Promise.reject({ success: false, error: "Cognito cannot retreive user data!" });
      }
    }

    // Finally
    return Promise.resolve({ success: true });
  }),

  refreshAllTokens: flow(function* refreshAllTokens() {
    console.log("token refresh !!");
    // 1. Refresh cognito tokens.
    try {
      const cognitoRefreshRes = yield Auth.currentSession();
      self.cognitoIdToken = cognitoRefreshRes?.idToken?.jwtToken || "";
      localStorage.setItem(LS_KEY_COGNITO_ID_TOKEN, cognitoRefreshRes?.idToken?.jwtToken || "");
    } catch (error) {
      return { success: false, error: `Error cognito token refresh: ${error}` };
    }

    // 2. Fetch new third party tokens.
    try {
      const webUserLoginRes = yield authAPI.post(__.AUTH_SERVICE.WEB_USER_LOGIN, {
        cognito_token: self.cognitoIdToken,
      });
      if (webUserLoginRes?.status === 200) {
        const decodedToken = jwtDecode(webUserLoginRes?.data?.adcuratio_token);

        self.folksUserToken = webUserLoginRes?.data?.adcuratio_token || "";
        self.folksUserId = decodedToken?.data?.user_id || "";
        self.setUserType(USER_TYPE.NORMAL);

        localStorage.setItem(LS_KEY_FOLKS_USER_TOKEN, webUserLoginRes?.data?.adcuratio_token || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_ID, decodedToken?.data?.user_id || "");
        localStorage.setItem(LS_KEY_FOLKS_USER_TYPE, USER_TYPE.NORMAL);
      } else {
        return { success: false, error: "User token refresh error!" };
      }
    } catch (error) {
      return { success: false, error: "User token refresh error!" };
    }

    // Finally
    return { success: true };
  }),

  resetPassword: flow(function* resetPassword(payload) {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN);
    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.UPDATE_USER_PASSWORD, payload, {
        headers: {
          token: folksUserToken,
        },
      });
      if (res?.status === 200) {
        return { success: true };
      } else {
        throw res;
      }
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.more_info || "Something went wrong with reset password api",
      };
    }
  }),

  forgotPassword: flow(function* forgotPassword(payload) {
    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.FORGOT_PASSWORD, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true, message: res?.data?.message || "Password Reset Request Sent!" });
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        status: error?.response?.status,
        message: error?.response?.data?.message || "Something went wrong!",
      });
    }
  }),

  confirmForgotPassword: flow(function* confirmForgotPassword(payload) {
    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.CONFIRM_FORGOT_PASSWORD, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true, message: res?.data?.message || "Password Reset Successful!" });
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        status: error?.response?.status,
        message: error?.response?.data?.error_message || "Something went wrong!",
      });
    }
  }),

  deleteAccount: flow(function* deleteAccount() {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN);
    const email_id = getParent(self).profileStore.email;
    const payload = {
      email_id,
    };
    const config = {
      headers: {
        token: folksUserToken,
      },
    };
    try {
      const res = yield authAPI.post(__.AUTH_SERVICE.DELETE_USER_ACC, payload, config);
      if (res?.status === 200) {
        getParent(self).notificationStore.triggerSuccess("Successfully requested for account deletion");
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Unable to delete the account");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError(error.message || "Unable to delete the account");
      return Promise.reject({ success: false, message: error.message || "Unable to delete the account" });
    }
  }),
});

const AuthStoreViews = (self) => ({
  get isGuestUser() {
    return self.folksUserType === USER_TYPE.GUEST;
  },
  get isNormalUser() {
    return self.folksUserType === USER_TYPE.NORMAL;
  },
});

export const AuthStore = types.model("AuthStore", AuthStoreModel).actions(AuthStoreActions).views(AuthStoreViews);
