// Actions store for details page.
import { flow, getParent, types } from "mobx-state-tree";
import API from "../../api/ReviewServiceAPI";
import VideoReviewAPI from "../../api/VideoReviewAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";
import { emotionModel } from "./models/actionModel";

export const ActionStore = types
  .model({
    emotions_list: types.optional(types.array(emotionModel), []),
    landing_page_emotions_list: types.optional(types.array(emotionModel), []),
  })
  .actions((self) => ({
    getEmojiObjectFromId(emojiId) {
      return self.emotions_list.find((obj) => obj._id === emojiId);
    },

    // -------------- Fetch Allowed Emotions -----------------
    fetchEmotions: flow(function* fetchEmotions({ landing }) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_ALLOWED_EMOTIONS({ landing }));
        if (res?.status === 200 && res?.data?.length) {
          if (!!landing) {
            self.landing_page_emotions_list = res.data;
            // Sets first emotion for reaction based feeds.
            getParent(self).feedsStore.setSelectedEmotionObj(res.data[0]);
          } else {
            self.emotions_list = res.data;
          }
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Bad response from server!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    // -------------- Get All reactions (bulk API) -----------------
    getUserReaction: flow(function* getUserReaction(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_ALL_REACTIONS(imdb_id));
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get Like and Dislike -----------------
    getLikeData: flow(function* getLikeData(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_LIKE_DATA(imdb_id));
        return res;
      } catch (error) {
        return error;
      }
    }),
    // --------------Like and Dislike Action-----------------
    setLikeDislike: flow(function* setLikeDislike(imdb_id, value) {
      const payload = { content_id: imdb_id, content_source: "imdb", is_liked: value };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.UPDATE_LIKE_DATA, payload);
        if (value === true && res?.data?.is_liked) {
          getParent(self).auditStore.updateLikedContent(imdb_id, "add_likeContent");
        } else if (value === false && res?.data?.is_liked === false) {
          getParent(self).auditStore.updateDisLikeContent(imdb_id, "add_disLikeContent");
        } else {
          getParent(self).auditStore.updateLikedContent(imdb_id, "remove_likeContent");
          getParent(self).auditStore.updateDisLikeContent(imdb_id, "remove_disLikeContent");
        }
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get user rating -----------------
    getUserRating: flow(function* getUserRating(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_RATING(imdb_id));
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Add user rating -----------------
    setUserRating: flow(function* setUserRating(imdb_id, rating) {
      const payload = { content_id: imdb_id, content_source: "imdb", rating: parseInt(rating).toString() };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.UPDATE_RATING, payload);
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get user seen -----------------
    getUserSeen: flow(function* getUserSeen(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_IS_SEEN(imdb_id));
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Add user seen -----------------
    setUserSeen: flow(function* setUserSeen(imdb_id, value) {
      const payload = { content_id: imdb_id, content_source: "imdb", seen: value ? true : false };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.UPDATE_IS_SEEN, payload);
        if (value === true && res?.data?.seen) {
          getParent(self).auditStore.updateSeenContent(imdb_id, "add_seenContent");
        } else if (value === false && !res?.data?.seen) {
          getParent(self).auditStore.updateSeenContent(imdb_id, "remove_seenContent");
        }
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get user emotion -----------------
    getUserEmotion: flow(function* getUserEmotion(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_EMOTION(imdb_id));
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Add user emotion -----------------
    setUserEmotion: flow(function* setUserEmotion(imdb_id, emotion_id) {
      const payload = { content_id: imdb_id, content_source: "imdb", emotion: emotion_id.toString() };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.UPDATE_EMOTION, payload);
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Fetch Top Emotions -----------------
    fetchTopEmotions: flow(function* fetchTopEmotions(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_TOP_EMOTIONS(imdb_id));
        if (res?.status === 200 && res?.data) {
          return Promise.resolve({ success: true, data: res.data });
        } else {
          throw new Error("Bad response from server!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    // -------------- Fetch user Not Interested -----------------
    getUserNotInterested: flow(function* getUserNotInterested(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_NOT_INTERESTED(imdb_id));
        if (res?.status === 200 && res?.data?._id) {
          return Promise.resolve({ success: true, content_id: res.data._id });
        } else {
          throw new Error("Something went wrong while fetching the user interested data!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError("Can't fetch the user interested data!");
        return Promise.reject({
          success: false,
          message: error.message || "Something went wrong while fetching the user interested data!",
        });
      }
    }),
    // -------------- Mark user Not Interested -----------------
    setUserNotInterested: flow(function* setUserNotInterested(
      imdb_id,
      category = "Not as per your taste?",
      reason = ""
    ) {
      const payload = {
        content_id: imdb_id,
        content_source: "imdb",
        reason_category: category.toString(),
        reason: reason.toString(),
      };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.UPDATE_NOT_INTERESTED, payload);
        if (res?.status === 200 && res?.data) {
          getParent(self).auditStore.updateNotInterestedContent(imdb_id, "add_notInterestedContent");
          return Promise.resolve({ success: true, data: res.data });
        } else {
          throw new Error("Something went wrong while updating the user interest data!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError("Unable to add the user interest data!");
        return Promise.reject({
          success: false,
          message: error.message || "Something went wrong while updating the user interest data!",
        });
      }
    }),
    // -------------- Delete user Not Interested -----------------
    deleteUserNotInterested: flow(function* deleteUserNotInterested(object_id) {
      try {
        const res = yield API.delete(__.REVIEW_SERVICE.DELETE_NOT_INTERESTED(object_id));
        if (res?.status === 200) {
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while deleting the user interested data!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError("Can't delete the user interested data!");
        return Promise.reject({
          success: false,
          message: error.message || "Something went wrong while deleting the user interested data!",
        });
      }
    }),

    // -------------- Get All Reviews ----------------- paginated.
    getAllReviews: flow(function* getAllReviews(imdb_id, skip, limit) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_ALL_REVIEWS({ imdb_id, skip, limit }));
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Mark review helpful -----------------
    setReviewHelpful: flow(function* setReviewHelpful(object_id) {
      const payload = { review: object_id };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.REVIEW_MARK_HELPFUL, payload);
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Report review abusive -----------------
    setReviewAbusive: flow(function* setReviewAbusive(object_id, review_body) {
      const payload = { review: object_id, body: review_body };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.REVIEW_MARK_ABUSE, payload);
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Remove review abusive -----------------
    deleteReviewAbusive: flow(function* deleteReviewAbusive(object_id) {
      try {
        const res = yield API.delete(__.REVIEW_SERVICE.REVIEW_REMOVE_ABUSE(object_id));
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get Review (User)-----------------
    getUserReview: flow(function* getUserReview(imdb_id) {
      try {
        const res = yield API.get(__.REVIEW_SERVICE.GET_USER_REVIEW(imdb_id));
        if (res.status === 200) return Promise.resolve({ success: true, data: res?.data });
      } catch (error) {
        return Promise.reject({
          success: false,
          message: error?.message,
          status: error?.response?.status,
        });
      }
    }),
    // -------------- Post Review (User) -----------------
    addUserReview: flow(function* addUserReview(imdb_id, title, reviewText, isPublic) {
      const payload = {
        content_id: imdb_id,
        content_source: "imdb",
        title: title.toString(),
        review_text: reviewText.toString(),
        color_code: [],
        is_public: isPublic ? true : false,
      };
      try {
        const res = yield API.post(__.REVIEW_SERVICE.ADD_USER_REVIEW, payload);
        return res;
      } catch (error) {
        return error;
      }
    }),
    // -------------- Delete Review (User) -----------------
    deleteUserReview: flow(function* deleteUserReview(object_id) {
      try {
        const res = yield API.delete(__.REVIEW_SERVICE.DELETE_USER_REVIEW(object_id));
        return res;
      } catch (error) {
        return error;
      }
    }),

    // -------------- Get Single Video Data -----------------
    getVideoReview: flow(function* getVideoReview(video_id) {
      try {
        const res = yield VideoReviewAPI.get(__.VIDEO_REVIEW_SERVICE.GET_DATA(video_id));
        if (res?.status === 200 && res?.data) {
          return Promise.resolve({ success: true, data: res?.data });
        } else {
          throw new Error("Something went wrong while fetching video review data!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError("Can't fetch the data from server");
        return Promise.reject({
          success: false,
          message: error.message || "Something went wrong while fetching video review data!",
        });
      }
    }),
    // -------------- Update Single Video like/dislike -----------------
    updateVideoLikeDislike: flow(function* updateVideoLikeDislike(video_id, is_liked) {
      const payload = { video_review_id: video_id, is_like: is_liked };
      try {
        const res = yield VideoReviewAPI.post(__.VIDEO_REVIEW_SERVICE.ADD_LIKE, payload);
        if (res?.status === 201 && res?.data) {
          return Promise.resolve({ success: true, data: res?.data });
        } else {
          throw new Error("Something went wrong while update the video review action!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError("Can't update the data from server");
        return Promise.reject({
          success: false,
          message: error.message || "Something went wrong while update the video review action!",
        });
      }
    }),
    // -------------- Report a Video Review -----------------
    reportVideoReview: flow(function* reportVideoReview(video_id, reason, other_reason) {
      const payload = { video_review_id: video_id, reason: reason, other_reason: other_reason };
      try {
        const res = yield VideoReviewAPI.post(__.VIDEO_REVIEW_SERVICE.REPORT, payload);
        if (res?.status === 201) {
          getParent(self).notificationStore.triggerSuccess("Reported successfully");
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while reporting!");
        }
      } catch (error) {
        getParent(self).notificationStore.triggerError(error?.response?.data?.detail || "Report already existed!");
        return Promise.reject({
          success: false,
          message: error?.response?.data?.detail || "Something went wrong while reporting!",
        });
      }
    }),
  }))
  .views((self) => ({
    // Views carry state updates:
    // Get emotion list.
    get getEmotionsList() {
      return self.emotions_list;
    },
    get getLikeBasedEmotion() {
      return self.emotions_list.filter((value) => value.mood === "like" || value.mood === "neutral");
    },
    get getDisLikeBasedEmotion() {
      return self.emotions_list.filter((value) => value.mood === "dislike" || value.mood === "neutral");
    },
    getFilteredTopEmotion(topEmotions) {
      return self.emotions_list.filter((value) => topEmotions.includes(value._id));
    },
  }));
