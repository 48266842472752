import { flow, types } from "mobx-state-tree";
import AnalyticsApi from "../../api/AnalyticsApi";
import { analyticsUrl } from "../../utils/helper";

const AnalyticsStoreModel = {};

const AnalyticsStoreActions = (self) => ({
  getAnalyticsPageView: flow(function* getAnalyticsPageView(eventType) {
    const payload = {
      "Adcuratio Token": localStorage.getItem("folks_user_token"),
      "App Name": "FolksMedia",
      source: "web",
      env: process.env.NODE_ENV,
      data: eventType, //array of events or single events,
    };
    try {
      const res = yield AnalyticsApi.post(analyticsUrl, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),
  getSubscribeAnalytics: flow(function* getSubscribeAnalytics(watchlist_id, eventType) {
    const payload = {
      watchlist_id: watchlist_id,
      content_id: "",
      subscription: eventType,
    };

    try {
      const res = yield AnalyticsApi.post(analyticsUrl, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),
});

const AnalyticsStoreViews = () => ({});

export const AnalyticsStore = types
  .model("AnalyticsStore", AnalyticsStoreModel)
  .actions(AnalyticsStoreActions)
  .views(AnalyticsStoreViews);
