import React from "react";
import FolksModal from "../folks-modal/FolksModal";
import styles from "./guestUserModal.module.css";

import bannerImg from "../../assets/images/guest_user_modal_img.png";
import { Link } from "react-router-dom";
import { useMst } from "../../store";
import { observer } from "mobx-react-lite";

const GuestUserModal = () => {
  const { modalControllerStore } = useMst();

  const handleCancel = () => {
    modalControllerStore.setGuestModalVisible(false);
  };

  return (
    <FolksModal
      visible={modalControllerStore.isGuestModalVisible}
      onCancel={handleCancel}
      width={"600px"}
      centered={true}
      zIndex={1001}
    >
      <div className={styles.modalContainer}>
        {/* header */}
        <div className={styles.modalHeader}>Sign up for more personalized experience.</div>

        {/* body */}
        <div>
          <div className={styles.imgContianer}>
            <img className={styles.img} src={bannerImg} alt="banner" />
          </div>
          <div className={styles.bodyTextContainer}>
            Get access to Rate, Like/Dislike, Playlist, Subscription, Add review & Much more.
          </div>
        </div>

        {/* footer */}
        <div>
          <Link to="/register">
            <button className={styles.signUpBtn} onClick={handleCancel}>
              <span>Sign up</span>
            </button>
          </Link>

          <div className={styles.footerTextContainer}>
            Already Have an Account?{" "}
            <Link to="/login">
              <span className={styles.login} onClick={handleCancel}>
                Login
              </span>
            </Link>
          </div>
        </div>
      </div>
    </FolksModal>
  );
};

export default observer(GuestUserModal);
