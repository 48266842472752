import React, { Suspense } from "react";
import { URLS } from "./routeURL";
import { USER_TYPE } from "../store/auth_store/models/authModel";
import FrontLoader from "../components/front-loader/FrontLoader";
import ErrorBoundary from "../components/error-boundary/ErrorBoundary";

// Landing Module pages
const LandingPage = React.lazy(() => import("../folks-landing-web/landing-page/LandingPage"));
const AboutCompany = React.lazy(() => import("../folks-landing-web/about-company/AboutCompany"));
const PrivacyPolicy = React.lazy(() => import("../folks-landing-web/privacy-policy/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("../folks-landing-web/terms-of-use/TermsOfUse"));

// Auth Module pages
const Login = React.lazy(() => import("../auth/login/Login"));
const Register = React.lazy(() => import("../auth/register/Register"));
const ForgotPass = React.lazy(() => import("../auth/forget-password/ForgotPass"));
const LinkedDevice = React.lazy(() => import("../auth/LinkedDevice"));

// Public User Profile/Playlist pages
const UserProfile = React.lazy(() => import("../user-profile/UserProfile"));
const ViewPlaylist = React.lazy(() => import("../view-playlist/ViewPlaylist"));

// Content Module pages
const ContentHome = React.lazy(() => import("../content-discovery/home/Home"));
const ContentMovies = React.lazy(() => import("../content-discovery/movies/Movies"));
const ContentShows = React.lazy(() => import("../content-discovery/shows/Shows"));
const ContentMetaExpand = React.lazy(() => import("../content-discovery/meta-expand/MetaExpand"));
const Search = React.lazy(() => import("../content-discovery/search/Search"));
const Details = React.lazy(() => import("../content-discovery/details/Details"));

// Rewards Module pages
const Rewards = React.lazy(() => import("../rewards/Rewards"));
const CommonMesage = React.lazy(() => import("../commonMessage/CommonMesage"));
// Products Module pages
const Products = React.lazy(() => import("../products/Products"));

// Account Module pages
const Setting = React.lazy(() => import("../user-account/setting/Setting"));
const AccountDelete = React.lazy(() => import("../user-account/account-security/delete-account/AccountDelete"));
const ResetPwd = React.lazy(() => import("../user-account/account-security/reset-pass/Reset"));
const EditProfile = React.lazy(() => import("../user-account/user-profile/EditProfile"));
const ViewProfile = React.lazy(() => import("../user-account/user-profile/ViewProfile"));
const ManageAddress = React.lazy(() => import("../user-account/manage-address/ManageAddress"));

const Referral = React.lazy(() => import("../user-account/referral/Referral"));
const Leaderboard = React.lazy(() => import("../user-account/referral/components/Leaderboard"));
const Achievements = React.lazy(() => import("../user-account/referral/components/Achievements"));
const Activity = React.lazy(() => import("../user-account/referral/components/Activity"));

const ContentLike = React.lazy(() => import("../user-account/manage-library/ContentLike"));
const ContentDisLIke = React.lazy(() => import("../user-account/manage-library/ContentDisLIke"));
const ContentSeen = React.lazy(() => import("../user-account/manage-library/ContentSeen"));
const ContentNotInterested = React.lazy(() => import("../user-account/manage-library/ContentNotInterested"));
const ManagePlaylist = React.lazy(() => import("../user-account/manage-library/manage-playlist/ManagePlaylist"));

const ReportTab = React.lazy(() => import("../user-account/report-bug/ReportTab"));
const License = React.lazy(() => import("../user-account/about/License"));

export const landingRoutes = {
  fm_landing: {
    id: "landing-1",
    path: URLS.LANDING,
    name: "Landing Page",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <LandingPage {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE],
  },
  fm_about_company: {
    id: "landing-2",
    path: URLS.ABOUT_COMPANY,
    name: "About Company",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <AboutCompany {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE, USER_TYPE.RESTRICTED],
  },
  fm_privacy_policy: {
    id: "landing-3",
    path: URLS.PRIVACY_POLICY,
    name: "Privacy Policy",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <PrivacyPolicy {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE, USER_TYPE.RESTRICTED],
  },
  fm_t_o_u: {
    id: "landing-4",
    path: URLS.TERMS_OF_USE,
    name: "Terms Of Use",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <TermsOfUse {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE, USER_TYPE.RESTRICTED],
  },
};

export const authRoutes = {
  login: {
    id: "auth-1",
    path: URLS.LOGIN,
    name: "Login",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Login {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.GUEST, USER_TYPE.NONE],
  },
  register: {
    id: "auth-2",
    path: URLS.REGISTER,
    name: "Register",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Register {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.GUEST, USER_TYPE.NONE],
  },
  forgot_pass: {
    id: "auth-3",
    path: URLS.FORGOT_PASS,
    name: "Forgot Password",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ForgotPass {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.GUEST, USER_TYPE.NONE],
  },
  link_device: {
    id: "auth-4",
    path: URLS.LINK_DEVICE,
    name: "Link Device",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <LinkedDevice {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID],
  },
};

export const publicRoutes = {
  public_user_profile: {
    id: "public-1",
    path: URLS.PUBLIC_USER_PROFILE(),
    name: "Public User Profile",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <UserProfile {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE],
  },
  public_user_playlist: {
    id: "public-2",
    path: URLS.PUBLIC_USER_PLAYLIST(),
    name: "Public User Playlist",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ViewPlaylist {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE],
  },
};

export const contentRoutes = {
  content_home: {
    id: "content-1",
    path: URLS.CONTENT_HOME,
    name: "Content Home",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentHome {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  content_movies: {
    id: "content-11",
    path: URLS.CONTENT_MOVIES,
    name: "Content Movies",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentMovies {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  content_shows: {
    id: "content-12",
    path: URLS.CONTENT_SHOWS,
    name: "Content Shows",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentShows {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  content_meta_expand: {
    id: "content-13",
    path: URLS.CONTENT_META_EXPAND(),
    name: "Content Meta Expand",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentMetaExpand {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  content_search: {
    id: "content-2",
    path: URLS.CONTENT_SEARCH,
    name: "Content Search",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Search {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  content_details: {
    id: "content-3",
    path: URLS.CONTENT_DETAILS(),
    name: "Content Details",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Details {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
};

export const accountRoutes = {
  settings: {
    id: "settings",
    path: URLS.ACCOUNT_SETTINGS,
    name: "Settings",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Setting {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST],
  },
  delete_account: {
    id: "delete_account",
    path: URLS.ACCOUNT_DELETE,
    name: "Delete Account",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <AccountDelete {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  reset_pass: {
    id: "reset_password",
    path: URLS.ACCOUNT_RESET_PASS,
    name: "Reset Password",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ResetPwd {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  edit_profile: {
    id: "edit_profile",
    path: URLS.ACCOUNT_EDIT_PROFILE,
    name: "Edit Profile",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <EditProfile {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  view_profile: {
    id: "view_profile",
    path: URLS.ACCOUNT_VIEW_PROFILE,
    name: "View Profile",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ViewProfile {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  address: {
    id: "addresses",
    path: URLS.ACCOUNT_ADDRESS,
    name: "Addresses",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ManageAddress {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  referral: {
    id: "referral",
    path: URLS.ACCOUNT_REFERRAL,
    name: "Referral",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Referral {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  leaderboard: {
    id: "leaderboard",
    path: URLS.ACCOUNT_LEADERBOARD,
    name: "Leaderboard",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Leaderboard {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  achievements: {
    id: "achievements",
    path: URLS.ACCOUNT_ACHIEVEMENTS,
    name: "Achievements",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Achievements {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  activities: {
    id: "activities",
    path: URLS.ACCOUNT_ACTIVITIES,
    name: "Activities",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Activity {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  content_like: {
    id: "content_you_like",
    path: URLS.ACCOUNT_CONTENT_LIKE,
    name: "Content you like",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentLike {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  content_dislike: {
    id: "content_you_dislike",
    path: URLS.ACCOUNT_CONTENT_DISLIKE,
    name: "Content you dislike",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentDisLIke {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  content_watched: {
    id: "already_see_content",
    path: URLS.ACCOUNT_CONTENT_WATCHED,
    name: "Already Watched content",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentSeen {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  content_not_interested: {
    id: "content_marked_not_interested",
    path: URLS.ACCOUNT_CONTENT_NOT_INTERESTED,
    name: "content marked not interested",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ContentNotInterested {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  content_manage_playlist: {
    id: "manage_playlist",
    path: URLS.ACCOUNT_MANAGE_PLAYLIST,
    name: "Playlist",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ManagePlaylist {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL],
  },
  report_bug: {
    id: "report_bugs",
    path: URLS.ACCOUNT_REPORT_BUG,
    name: "Report Bugs",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <ReportTab {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.GUEST, USER_TYPE.KID],
  },
  license: {
    id: "license",
    path: URLS.ACCOUNT_LICENSE,
    name: "License",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <License {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.GUEST, USER_TYPE.KID],
  },
};

export const rewardRoutes = {
  reward_entrypoint: {
    id: "reward_1",
    path: URLS.REWARD_ENTRYPOINT,
    name: "Reward Entrypoint",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Rewards {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.GUEST],
  },
};

export const commonRoutes = {
  common_entrypoint: {
    id: "common_1",
    path: URLS.COMMON_MESSAGE(),
    name: "Common message entry point",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <CommonMesage {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.KID, USER_TYPE.GUEST, USER_TYPE.NONE],
  },
};

export const productRoutes = {
  product_entrypoint: {
    id: "product_1",
    path: URLS.PRODUCT_ENTRYPOINT,
    name: "Product Entrypoint",
    component: (routeProps) => (
      <ErrorBoundary>
        <Suspense fallback={<FrontLoader />}>
          <Products {...routeProps} />
        </Suspense>
      </ErrorBoundary>
    ),
    access: [USER_TYPE.NORMAL, USER_TYPE.GUEST],
  },
};

export const allRouteList = [
  ...Object.values(landingRoutes),
  ...Object.values(authRoutes),
  ...Object.values(publicRoutes),
  ...Object.values(contentRoutes),
  ...Object.values(rewardRoutes),
  ...Object.values(productRoutes),
  ...Object.values(accountRoutes),
  ...Object.values(commonRoutes),
];

export const guestUserRouteList = allRouteList.filter((obj) => obj?.access.includes(USER_TYPE.GUEST));
export const normalUserRouteList = allRouteList.filter((obj) => obj?.access.includes(USER_TYPE.NORMAL));
export const kidUserRouteList = allRouteList.filter((obj) => obj?.access.includes(USER_TYPE.KID));
export const nonUserRouteList = allRouteList.filter((obj) => obj?.access.includes(USER_TYPE.NONE));
export const restrictedUserRouteList = allRouteList.filter((obj) => obj?.access.includes(USER_TYPE.RESTRICTED));
export const restrictedUserRoutePathList = restrictedUserRouteList.map((obj) => obj.path);
